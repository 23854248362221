<template>
  <div style="font-size: 16px;">
    <div class="shopStore-container">
      <div class="shopStore-head">
        <!-- <div> 返回按钮 -->
          <div @click="handleBack()" v-if="sourceType && sourceType != 3 && sourceType != 7" class="shopStore-head_btn">
            <img class="shopStore-head_btn_icon" src="@/assets/shop/icon/shopIndex.jpg" alt="">
            <span class="shopStore-head_btn_text">{{ $t('shop_detail_backBtn') }}</span>
          </div>
          <img @click="handleBack()" v-else class="shopStore-head-back" src="@/assets/common/back-icon.jpg" alt="">
        <!-- </div> -->
        
        <img class="shopStore-head-changeLong" :src="languageType == 'en' ?  require('@/assets/shop/icon/langCn.png') : require('@/assets/shop/icon/langEn.png')" alt="" @click="navTranslate()">
      </div>
      <div class="store">
        <!-- 邮件片单 跳转进 详情页 -->
        <div class="store-nav"
            @mouseleave="handleMenuContainerMouseLeave()">
          <!-- <div class="footer-logo"
              @click="jumpInterlibHome">
            <img src="@/assets/store/store_interlib_logo.png"
                alt="" />
          </div> -->
          <div v-if="subjectId" class="store-nav-name" ref="storeNavNameRef">
            <span>{{ ['cn','zh'].includes(languageType)?currentSbjectInfo.subjectName:currentSbjectInfo.subjectNameEn }}</span>
          </div>

          <img src="@/assets/store/store_nav_top.png"
              class="scroll-up-btn"
              @click="scrollPage(-1)"
              v-show="showScrollUp"
              alt=""><!-- 列表顶部的按钮 -->

          <div class="store-left-nav"
              :style="{ justifyContent: justifyContent }"
              ref="storeLeftContainer"
              @scroll="handleScroll">
            <div class="menu-container">
              <div class="menu-item-bg"
                  :style="{top: menuItemBgTop}"></div>
              <div class="menu-item-hovered-bg"
                  :style="{top: menuItemHoveredBgTop}"
                  v-show="isHoveredBgVisible"></div>
              <div v-for="(item, index) in filmListData"
                  :key="index"
                  class="menu-item"
                  :class="{'is-selected': item.isSelect === true, 'is-hovered': hoverItemIndex === index && item.isSelect === false}"
                  @click="handleItemClick(item, index)"
                  @mouseover="handleItemMouseOver(item, index)"
                  :ref="`menuItem-${index}`">
                <!-- <div class="content-container"
                    v-html="item.code"></div> -->
                <div class="nav-content-box">
                  <img class="nav-content-img" :src="item.img?.imgUrl" alt="">
                  <div class="nav-content-box-mask">{{ item.type }}</div>
                </div>
                <span v-show="!item.isSelect && hoverItemIndex !== index">{{ item.name }}</span>
              </div>
            </div>
          </div>

          <img src="@/assets/store/store_nav_bottom.png"
              class="scroll-down-btn"
              @click="scrollPage(1)"
              v-show="showScrollDown"
              alt=""><!-- 列表底部的按钮 -->
        </div>

        <div class="store-container">
          <shopDetailContainerPage :issuerInfo="issuerInfo" :id="dramaId" :shopId="shopId"></shopDetailContainerPage>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { getSaloonListDataApi, getSubjectListApi } from '@/api/shop/shopApi.js'
import { getPresignedUrlApi } from '@/api/homeApi.js'
import { detailInfoApi } from '@/api/detailApi.js'

import shopDetailContainerPage from '@/views/shopDetail/shopDetailContainerPage.vue'
import { storeFilmListApi } from '@/api/storeApi.js'
import { recordStoreLogActionApi } from '@/api/userLogApi.js'


export default {
  components: {
    shopDetailContainerPage
  },

  data () {
    return {
      currentSbjectInfo: {}, // 当前专题信息
      sourceType: 3, // 页面跳转来源
      subjectId: '', // 专题id
      shopId: '', // 店铺id
      dramaId: '', // 单剧id
      languageType: "", // 语言类型
      bmsUserId: '',
      bmsUserIdEncrypt: '',

      filmListData: [],
      justifyContent: 'center',
      showScrollUp: false,
      showScrollDown: true,
      hoverItemIndex: null,
      menuItemBgTop: '30px',
      menuItemBgTopData: 30,
      // menuItemHeight: 140,
      menuItemHeight: 169,
      menuItemHoveredBgTop: null, // 新增属性，存储menu-item-hovered-bg的top值
      isHoveredBgVisible: false, // 新增属性，控制menu-item-hovered-bg的可见性
      hoverTransitionTimeout: null, // 用于延迟动画的定时器
      selectedItemInterlibContentId: undefined,
      issuerInfo: {},
      deviceType: 0,//0 :笔记本 1：PC 2：手机
    }
  },
  created () {
    
    if(this.$route.query?.drama_id) {
      this.dramaId = this.$route.query.drama_id
    }
    if(this.$route.query?.shop_id) {
      this.shopId = this.$route.query.shop_id
    }
    if(this.$route.query?.subject_id) {
      this.subjectId = this.$route.query.subject_id
    }
    if(this.$route.query?.type) {
      this.sourceType = this.$route.query.type
    }
    if(this.$route.query?.bms_user_id) {
      this.bmsUserId = this.$route.query.bms_user_id
    }
    if(this.$route.query?.bms_user_enid) {
      this.bmsUserIdEncrypt = this.$route.query.bms_user_enid
    }

    this.shopId = this.getUrlKey('shop_id')
    this.subjectId = this.getUrlKey('subject_id')
    this.dramaId = this.getUrlKey('drama_id')
    this.sourceType = this.getUrlKey('type')
    this.bmsUserId = this.getUrlKey('bms_user_id')
    this.bmsUserIdEncrypt = this.getUrlKey('bms_user_enid')

    

    // deviceType: '0',//0 :笔记本 1：PC 2：手机
    var mediaQueryPC = window.matchMedia('(min-width: 1920px)');
    if (mediaQueryPC.matches) {
      this.deviceType = 1;
      this.menuItemHeight = 169 // 140
    } else {
      var mediaQuery = window.matchMedia('(max-width: 750px)');
      // var mediaQuery = window.matchMedia('(max-width: 1024px)');
      if (mediaQuery.matches) {
        this.deviceType = 2;
        this.menuItemHeight = 92 // 72
        let languageLinkText = ''

        if (['zh','cn'].includes(this.languageType) ) {
          languageLinkText = 'cn'
        } else {
          languageLinkText = 'en'
        }
        location.href=`http://${window.location.hostname}:${window.location.port}/mobile/${languageLinkText}/shopDramaDetail?shop_id=${this.$route.query?.shop_id}&drama_id=${this.$route.query?.drama_id}&type=${this.$route.query?.type || ''}&bms_user_id=${this.$route.query?.bms_user_id || ''}&bms_user_enid=${this.$route.query?.bms_user_enid || ''}`
        return;
      } else {
        this.deviceType = 0;
        this.menuItemHeight = 169 // 140
      }
    }

    // 获取设备类型
    console.log('this.deviceType:', this.deviceType);
  },
  beforeDestroy () {
    // 其他清理工作不变
    clearTimeout(this.hoverTransitionTimeout);
  },
  mounted () {
    this.reloadLocationLanguageData()
    if(this.subjectId && this.subjectId != 'undefined') {
      this.getThemeList()
      // 获取当前店铺下专题列表信息
      this.fetchThemeData();
    } else {
      this.fetchDetailData(this.dramaId)
    }
    
    // this.fetchStoreRecordLogData() // 埋点
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.checkContainerHeight);
  },
  methods: {
    // 返回按钮
    handleBack() {
      console.log('返回', this.sourceType)
      let pageName = 'language-shopSaloon'
      let languageLinkText = ''
      if (['zh','cn'].includes(this.languageType) ) {
        languageLinkText = 'cn'
      } else {
        languageLinkText = 'en'
      }
      if(this.sourceType && this.sourceType != 3 && this.sourceType != 7) {
        // pageName = 'language-shop'
        // this.$router.push({
        //   name: pageName,
        //   query: {
        //     type: 3,
        //     shop_id: this.shopId,
        //     bms_user_id: this.bmsUserId,
        //     bms_user_enid: this.bmsUserIdEncrypt,
        //   }
        // })
        
        location.href=`http://${window.location.hostname}:${window.location.port}/${languageLinkText}/shop?type=3&shop_id=${this.shopId}&bms_user_id=${this.bmsUserId}&bms_user_enid=${this.bmsUserIdEncrypt}`
      } else if(this.sourceType && this.sourceType == 7) {
        // this.$router.back()
        // location.href = document.referrer;
        location.href=`http://${window.location.hostname}:${window.location.port}/${languageLinkText}/shop?type=3&shop_id=${this.shopId}`
      } else {
        // this.$router.push({
        // name: pageName,
        //   query: {
        //     type: 3,
        //     shop_id: this.shopId,
        //     bms_user_id: this.bmsUserId,
        //     bms_user_enid: this.bmsUserIdEncrypt,
        //     subject_id: this.subjectId,
        //   }
        // })
        location.href=`http://${window.location.hostname}:${window.location.port}/${languageLinkText}/shopSaloon?type=3&subject_id=${this.subjectId}&shop_id=${this.shopId}&bms_user_id=${this.bmsUserId}&bms_user_enid=${this.bmsUserIdEncrypt}`
      }
    },
    reloadLocationLanguageData () {
      // 检查本地存储中的语言类型
      const language = localStorage.getItem('locationLanguageType')

      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
    },
     //中英文切换
    navTranslate () {
      console.log("中英文切换:", this.languageType);
      if (this.languageType == 'en') {
        localStorage.setItem('locationLanguageType', 'zh')
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
      const location_href = window.location.href;
      if (location_href.includes('/cn')) {
        let modifiedHref = location_href.replace('/cn', '/' + 'en');
        window.location.href = modifiedHref;
      } else {
        let modifiedHref = location_href.replace('/en', '/' + 'cn');
        window.location.href = modifiedHref;
      }
    },
    //获取专题theme数据
    fetchThemeData () {
      const getData = {
        "shop_id": this.shopId,
        language: this.languageType
      }
      getSubjectListApi(getData)
        .then((res) => {
          console.log('111111111获取banner数据', res)
          if(res.data.code === 200) {
            let _res = res.data.data || []

            if(this.subjectId) {
              _res.forEach(ele=>{
                if(ele.subjectId === this.subjectId) {
                  this.currentSbjectInfo = ele
                }
              })
            }
          }
        })
        .catch((error) => {
        })
    },
    //获取详情数据
    fetchDetailData (id) {
      detailInfoApi(id, this.languageType)
        .then(async (res) => {
          if (res.data.code === 200) {
            // this.detailData = res.data.data
            const _res = res.data.data || {}
            console.error('22222222222详情', res.data.data)
            let posterUrl = ''
            // 获取海报图url
            if (_res.poster && _res.poster.length) {
              const firstPoster = _res.poster[0];
              try {
                const imageRes = await getPresignedUrlApi(firstPoster);
                if (imageRes.data.code === 200) {
                  posterUrl = imageRes.data.data.url || require('@/assets/nav/mine-login-default.jpg');
                }
                this.filmListData = [{
                  img: {
                    imgUrl: posterUrl
                  },
                  type: _res.typeName,
                  projectEnName: ['cn','zh'].includes(this.languageType)?_res.projectName:_res.projectNameEn,
                  id: _res.id,
                  isSelect: true
                }];
                this.$nextTick( () => {
                  this.checkContainerHeight();
                  window.addEventListener('resize', this.checkContainerHeight);
                  this.hoverItemIndex = null;
                  this.isHoveredBgVisible = false;

                  this.selectedItemInterlibContentId = _res.id
                  console.log("111111111:", this.filmListData);
                  // 计算menu-item-bg新的top值
                  this.calculateMenuItemBgPosition(0);
                  // 滚动逻辑
                  this.scrollToItem(0);
                })
          
              } catch (error) {
                // 错误处理
              }
            }
            }
        })
        .catch((error) => {
        })
    },
    // 获取url参数
    getUrlKey(name) {
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || ''
    },
    //获取专题下片剧列表数据
    getThemeList () {
      console.log('获取专题列表数据')
      let postData = {
        // "shopId": this.shopId,//shopid
        "subjectId": this.subjectId,//题材id
        "language": this.languageType,//语言
        "roomType": 1, //1-接待室 2-vip
        "region": null,
        pageSize: 9999,
        pageNum: 1
      }
      console.log('postData',postData)
      getSaloonListDataApi(postData)
        .then(async (res) => {
          console.error('获取专题列表数据',res)
          if(res.data.code === 200) {
            let arr = res.data.data.list || [];
            // this.total = res.data.data.total || 0
            this.filmListData = arr;
            // 查找匹配的索引
            let matchingIndex = 0;
            this.filmListData && this.filmListData.forEach((ele,e)=>{
              if(!ele.img.imgUrl && ele.img.fileId) {
                getPresignedUrlApi(ele.img.fileId)
                  .then( (res) => {
                    // console.log('获取图片地址',res)
                    if (res.data.code === 200) {
                      let urlCon = res.data.data?.url
                      if(!urlCon) {
                        urlCon = ['cn','zh'].includes(this.languageType) ? this.nopicImg : this.nopicImgEn
                      }
                      this.$set(ele.img,'imgUrl',urlCon)
                    }
                  })
                  .catch((error) => {
                    console.error(error)
                  })
              }
              this.$set(ele,'isSelect', ele.id == this.dramaId)
              if(ele.id == this.dramaId) {
                matchingIndex = e
              }
              
            })

            if(this.filmListData && this.filmListData.length) {
              this.$nextTick(() => {
                this.checkContainerHeight();
                window.addEventListener('resize', this.checkContainerHeight);
                this.hoverItemIndex = null;
                this.isHoveredBgVisible = false;

                // 计算menu-item-bg新的top值
                this.calculateMenuItemBgPosition(matchingIndex);

                // 滚动逻辑
                this.scrollToItem(matchingIndex);
              });
            }
            console.log(33333333, this.filmListData)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    checkContainerHeight () {
      const container = this.$refs.storeLeftContainer;
      const rect = container.getBoundingClientRect();
      const heightDifference = container.scrollHeight - rect.height;
      if (heightDifference > 0) {
        this.justifyContent = 'flex-start';
        this.menuItemBgTopData = 30
      } else {
        this.justifyContent = 'center';
        if (this.deviceType === 1) {
          this.menuItemBgTopData = 138// PC
        } else {
          this.menuItemBgTopData = 82// 笔记本
        }

        // 确保容器和第一个菜单项都已被正确渲染
        const firstMenuItem = this.$refs['menuItem-0'][0]; // 假设第一个菜单项的ref为'menuItem-0'

        if (container && firstMenuItem) {
          // 获取第一个菜单项的位置信息
          const menuItemRect = firstMenuItem.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();

          // 计算距离顶部的距离
          const topDistance = menuItemRect.top - containerRect.top;

          const firstItem = this.filmListData[0]
          if (firstItem.isSelect) {
            this.menuItemBgTopData = topDistance + 15;
          } else {
            this.menuItemBgTopData = topDistance + 8;
          }
          console.log("topDistance:", topDistance);
        }
      }
      this.handleScroll(); // 初始化按钮显示
    },
    scrollPage (direction) {
      const storeNavName = this.$refs.storeNavNameRef || '';
      const nameH = storeNavName.clientHeight || 64
      const container = this.$refs.storeLeftContainer;
      container.scrollBy({ top: direction * container.clientHeight, behavior: 'smooth' });
    },
    handleScroll () {
      const container = this.$refs.storeLeftContainer;
      this.showScrollUp = container.scrollTop > 0;
      // 增加一个小的容忍值来确保即使有小的计算误差也能正确判断是否滚动到底部
      const atBottom = container.scrollHeight - container.scrollTop <= container.clientHeight + 1;
      this.showScrollDown = !atBottom;
    },
    handleItemClick (item, index) {
      if (item.isSelect) return

      // this.fetchStoreRecordEndLogData()

      this.filmListData.forEach(tempItem => {
        tempItem.isSelect = false;
      });

      this.$nextTick(() => {
        // 设置当前点击项的isSelect为true
        item.isSelect = true;

        console.log('handleItemClick.filmListData:', this.filmListData);

        this.dramaId = item.id;
        this.hoverItemIndex = null;
        this.isHoveredBgVisible = false;
        // localStorage.setItem('locationStoreClickInterlibContentId', item.id ? item.id : '')

        console.log("selectedItemInterlibContentId:", this.dramaId);
        // 计算menu-item-bg新的top值
        this.calculateMenuItemBgPosition(index);
        // this.fetchStoreRecordLogData()
      });
    },
    calculateMenuItemBgPosition (index) {
      // 单一一个时样式加像素
      let addLen = 0
      if(this.filmListData.length===1) {
        addLen = 3
      }
      const newTop = index * this.menuItemHeight + this.menuItemBgTopData;
      this.menuItemBgTop = `${newTop + addLen}px`;
    },
    handleItemMouseOver (item, index) {
      if (item.isSelect) return
      if (this.hoverItemIndex !== index && this.menuItemHoveredBgTop === null) {
        clearTimeout(this.hoverTransitionTimeout);
        this.calculateMenuItemHoveredBgPosition(index);
        this.hoverItemIndex = index;
      } else {
        clearTimeout(this.hoverTransitionTimeout);
        this.hoverTransitionTimeout = setTimeout(() => {
          this.calculateMenuItemHoveredBgPosition(index);
          this.hoverItemIndex = index;
        }, 50);
      }
      this.isHoveredBgVisible = true;
    },
    handleItemMouseLeave (index) {
      if (this.hoverItemIndex === index) {
        this.hoverItemIndex = null;
      }
      this.isHoveredBgVisible = false;
    },
    handleMenuContainerMouseLeave () {
      this.hoverTransitionTimeout = setTimeout(() => {
        this.hoverItemIndex = null;
        this.isHoveredBgVisible = false;
      }, 50);
    },
    calculateMenuItemHoveredBgPosition (index) {
      const newTop = index * this.menuItemHeight + this.menuItemBgTopData;
      this.menuItemHoveredBgTop = `${newTop}px`;
    },
    jumpInterlibHome () {
      // 在新标签页打开
      window.open(this.$router.resolve({ path: '/en/' }).href, '_blank');
    },
    scrollToItem (index) {
      const itemComponent = this.$refs[`menuItem-${index}`][0];
      const container = this.$refs.storeLeftContainer;
      const storeNavName = this.$refs.storeNavNameRef || '';
      console.log('storeNavName', itemComponent, container, storeNavName)
      if (itemComponent && container) {
        this.$nextTick(()=>{
          const nameH = storeNavName.clientHeight || 64
          const itemTop = itemComponent.offsetTop;
          container.scrollTop = itemTop - container.offsetTop + nameH;
        })
        
      }
      if (index === this.filmListData.length - 1) {
        // 添加延迟执行滚动
        setTimeout(() => {
          this.scrollPage(1);
        }, 100);
      }

      if (this.deviceType === 2 && index > 3) {
        // 添加延迟执行滚动
        setTimeout(() => {
          this.scrollPage(1);
        }, 100);
      }
    },
    //获取店铺详情埋点数据
    fetchStoreRecordLogData () {
      const locationStoreClickInterlibContentId = localStorage.getItem('locationStoreClickInterlibContentId')
      recordStoreLogActionApi('2', locationStoreClickInterlibContentId)
        .then((res) => {
          if (res.data.code === 200) {
          }
        })
        .catch((error) => {
        })
    },
    //获取店铺详情埋点（浏览结束）数据
    fetchStoreRecordEndLogData () {
      const locationStoreClickInterlibContentId = localStorage.getItem('locationStoreClickInterlibContentId')
      recordStoreLogActionApi('5', locationStoreClickInterlibContentId)
        .then((res) => {
          if (res.data.code === 200) {
          }
        })
        .catch((error) => {
        })
    },
  },
}
</script>

<style lang="less" scoped>
.shopStore-container {
  font-family: 'Source Han Sans SC-Regular';
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  background-image: url("~@/assets/bg/interlib-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
.shopStore-head {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}

.shopStore-head-back {
  width: 10px;
  height: auto;
  margin-right: 60px;
  cursor: pointer;
}
.shopStore-head_btn {
  cursor: pointer;
  background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe);
  border-radius: 50px;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 28px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-top: 4px;
  .shopStore-head_btn_icon {
    width: 22px;
    margin-right: 6px;
  }
  .shopStore-head_btn_text {
    margin-top: -2px;
  }
}
.shopStore-head-changeLong {
  width: 25px;
  margin-right: 5px;
  cursor: pointer;
}

.store {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: row;
  /* background-color: black;
  background-image: url("~@/assets/bg/interlib-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center; */
}

.store-nav {
  // margin: 30px 15px 30px 30px;
  // margin: 0 15px 30px 30px;
  margin: 0 0 30px 30px;
  // width: 160px;
  width: 140px;
  // height: calc(100vh - 110px);
  height: calc(100vh - 80px);
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.footer-logo {
  margin-top: 20px;
  height: 30px;
}

.footer-logo img {
  height: 100%;
}

.store-container {
  flex-grow: 1;
  overflow: scroll;
  // margin-top: 30px;
}

.store-left-nav {
  margin-top: 80px;
  height: calc(100% - 160px);
  border-radius: 8px;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  position: relative;
}

.menu-item {
  transition: transform 0.3s ease;
  margin-top: 22px;
  margin-bottom: 60px;
  width: 70px;
  height: 80px;
  position: relative;
  opacity: 0.4;
}

.menu-item span {
  margin-top: -298px;
  font-size: 10px;
  color: #f0f0f0;
  font-family: Source Han Sans SC-Regular;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
  line-height: 1.2;
  width: 130px;
  margin-left: -30px;
  transition: transform 0.3s ease;
}

.menu-item-bg {
  position: absolute;
  left: 21.5px;
  top: 0;
  width: 115px;
  height: 120px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.15);
  transition: top 0.4s ease; /* 添加过渡效果 */
  margin-top: -30px;
}

.menu-item-hovered-bg {
  position: absolute;
  left: 23.5px;
  top: 0px;
  margin-top: -28px;
  width: 113px;
  height: 113px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  transition: top 0.4s ease-out; /* 确保动画平滑 */
}

.menu-item.is-selected {
  transform: scale(1.2);
  transition: transform 0.3s ease;
  opacity: 1;
}

.menu-item.is-hovered {
  transform: scale(1.1);
  transition: transform 0.3s ease;
  opacity: 0.7;
}

.menu-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.scroll-up-btn {
  position: absolute;
  margin-top: 30px;
  left: 50%;
  width: 10px;
  transform: translateX(-50%);
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 10;
}

.scroll-down-btn {
  position: absolute;
  left: 50%;
  width: 10px;
  margin-bottom: 10px;
  transform: translateX(-50%);
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 10;
}

.scroll-up-btn {
  // top: 10px;
}

.scroll-down-btn {
  // bottom: 10px;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.content-container {
  transform: scale(0.2);
  transform-origin: top left;
  margin-left: -3px;
}

.nav-content-box {
  /* transform: scale(0.2);
  transform-origin: top left; */
  margin-left: -3px;
  height: calc( 100% - 16px );
  /* background: #333; */
  position: relative;
}

.nav-content-box-mask {
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, #000 , #33333393 , transparent);;
  color: #fff;
  font-size: 10px;
  height: 45px;
  padding-top: 20px;
  line-height: 25px;
  width: 100%;
  z-index: 2;
  text-align: center;
  // opacity: 0.6;
  border-radius: 0 0 8px 8px;
}

.nav-content-img {
  width: 100%;
  object-fit: cover;
}

.store-film-expire {
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 16px;
  font-family: "Source Han Sans SC-Normal";
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
</style>


<style scoped>
/* 左侧nav相关 */
div,p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.store {
  margin: 0;
  padding: 0;
  width: 100vw;
  /* height: 100vh; */
  height: calc( 100vh - 50px );
  display: flex;
  flex-direction: row;
  /* background-color: black; */
  /* background-image: url("~@/assets/bg/interlib-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center; */
}

.store-nav {
  /* margin: 30px 15px 30px 30px; */
  /* margin: 0 15px 30px 30px; */
  margin: 0 0 30px 30px;
  /* width: 160px; */
  width: 140px;
  /* height: calc(100vh - 110px); */
  height: calc(100vh - 80px);
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.store-nav-name {
  color: #fff;
  margin: 30px auto 10px;
  text-align: center;
  padding: 0 20px;
  min-height: 64px;
  max-height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 14px;
}

.footer-logo {
  margin-top: 20px;
  height: 30px;
}

.footer-logo img {
  height: 100%;
}

.store-container {
  flex-grow: 1;
  overflow: scroll;
}

.store-left-nav {
  margin-top: 10px;
  /* height: calc(100% - 224px); */
  height: calc(100% - 152px);
  border-radius: 8px;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  position: relative;
}

.menu-item {
  transition: transform 0.3s ease;
  margin-top: 22px;
  margin-bottom: 60px;
  width: 70px;
  height: 109px;
  position: relative;
  /* opacity: 0.4; */
}

.menu-item span {
  margin-top: 10px;
  font-size: 10px;
  color: #f0f0f0;
  font-family: Source Han Sans SC-Regular;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
  line-height: 1.2;
  width: 130px;
  margin-left: -30px;
  transition: transform 0.3s ease;
}

.menu-item-bg {
  position: absolute;
  left: 21.5px;
  top: 0;
  width: 115px;
  height: 134px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.15);
  transition: top 0.4s ease; /* 添加过渡效果 */
  margin-top: -30px;
}

.menu-item-hovered-bg {
  position: absolute;
  left: 21px;
  top: 0px;
  margin-top: -28px;
  width: 113px;
  height: 130px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  transition: top 0.4s ease-out; /* 确保动画平滑 */
}

.menu-item.is-selected {
  transform: scale(1.2);
  transition: transform 0.3s ease;
  opacity: 1;
}

.menu-item.is-hovered {
  transform: scale(1.1);
  transition: transform 0.3s ease;
  opacity: 0.7;
}

.menu-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.scroll-up-btn {
  position: absolute;
  /* margin-top: 90px; */
  margin-top: 84px;
  left: 50%;
  width: 10px;
  transform: translateX(-50%);
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 10;
}

.scroll-down-btn {
  position: absolute;
  left: 50%;
  width: 10px;
  /* margin-bottom: 30px; */
  margin-bottom: 10px;
  transform: translateX(-50%);
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 10;
}

.scroll-up-btn {
  top: 10px;
}

.scroll-down-btn {
  /* bottom: 10px; */
  bottom: 0;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.content-container {
  transform: scale(0.2);
  transform-origin: top left;
  margin-left: -3px;
  background: #333;
}

.nav-content-box {
  /* transform: scale(0.2);
  transform-origin: top left; */
  margin-left: -3px;
  height: calc( 100% - 16px );
  /* background: #333; */
  position: relative;
}

.nav-content-box-mask {
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, #000 , #33333394 , transparent);;
  color: #fff;
  font-size: 10px;
  height: 45px;
  padding-top: .2rem;
  line-height: 25px;
  width: 100%;
  z-index: 2;
  text-align: center;
  /* opacity: 0.6; */
  border-radius: 0 0 8px 8px;
}

.nav-content-img {
  width: 100%;
  object-fit: cover;
}

.store-film-expire {
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 16px;
  font-family: "Source Han Sans SC-Normal";
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
</style>

<style scoped>
/* 左侧nav相关 */
/* 移动设备 */
@media only screen and (max-width: 1024px) {
  /* 左侧nav相关 移动设备 */
  .store {
    margin: 0;
    padding: 0;
    width: 100vw;
    /* height: 100vh; */
    height: calc( 100vh - 50px );
    display: flex;
    flex-direction: row;
    /* background-color: black; */
    /* background-image: url("~@/assets/bg/interlib-bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center; */
  }

  .store-nav {
    /* margin: 20px 0px 30px 5px; */
    margin: 0 0px 30px 5px;
    /* width: 140px; */
    /* height: calc(100vh - 60px); */
    height: calc(100vh - 40px);
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

  .store-nav-name {
    padding: 0 10px;
  }

  .footer-logo {
    margin-top: 10px;
    height: 18px;
  }

  .footer-logo img {
    height: 100%;
  }

  .store-container {
    flex-grow: 1;
    overflow: scroll;
  }

  .store-left-nav {
    margin-top: 10px;
    height: calc(100% - 152px);
    border-radius: 8px;
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    position: relative;
  }

  .menu-item {
    transition: transform 0.3s ease;
    margin-top: 22px;
    margin-bottom: 20px;
    width: 40px;
    height: 70px;
    position: relative;
    /* opacity: 0.4; */
    margin-left: 10px;
  }

  .menu-item span {
    margin-top: 10px;
    font-size: 8px;
    color: #f0f0f0;
    font-family: Source Han Sans SC-Regular;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: center;
    line-height: 1.2;
    width: 70px;
    margin-left: -20.5px;
    transition: transform 0.3s ease;
  }

  .menu-item-bg {
    position: absolute;
    left: 9.5px;
    top: 0;
    width: 60px;
    height: 85px;
    /* height: 65px; */
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.15);
    transition: top 0.4s ease; /* 添加过渡效果 */
    margin-top: -22.5px;
  }

  .menu-item-hovered-bg {
    position: absolute;
    left: 23.5px;
    top: 0px;
    margin-top: -28px;
    width: 60px;
    height: 85px;
    /* height: 65px; */
    border-radius: 8px;
    border: none;
    transition: top 0.4s ease-out; /* 确保动画平滑 */
  }

  .menu-item.is-selected {
    transform: scale(1.2);
    transition: transform 0.3s ease;
    opacity: 1;
  }

  .menu-item.is-hovered {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    opacity: 0.7;
  }

  .menu-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  .scroll-up-btn {
    position: absolute;
    margin-top: 90px;
    left: 45px;
    width: 10px;
    transform: translateX(-50%);
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    z-index: 10;
  }

  .scroll-down-btn {
    position: absolute;
    left: 45px;
    width: 10px;
    margin-bottom: -10px;
    transform: translateX(-50%);
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    z-index: 10;
  }

  .scroll-up-btn {
    top: 10px;
  }

  .scroll-down-btn {
    /* bottom: 10px; */
    bottom: 0;
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }

  .content-container {
    transform: scale(0.1);
    transform-origin: top left;
    margin-left: -3px;
  }

  .store-film-expire {
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 16px;
    font-family: "Source Han Sans SC-Normal";
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
}
</style>


