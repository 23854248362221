// v-imgerror="备用图地址" 
// 当图片加载失败时，隐藏图裂标识
export const imgerror = {
  inserted(el, binding) {
   // el 指令所在的元素  也就是我们的img标签
   // binding 指令的相关信息对象
    el.onerror = function() {
      // console.log('图片加载失败了'), 设置备用图片地址
      el.src = binding.value      // binding.value 指令的值 也就是我们的defaultImg
    }
  }
}