import { render, staticRenderFns } from "./shopDetailGenresPage.vue?vue&type=template&id=16b7b4a6&scoped=true"
import script from "./shopDetailGenresPage.vue?vue&type=script&lang=js"
export * from "./shopDetailGenresPage.vue?vue&type=script&lang=js"
import style0 from "./shopDetailGenresPage.vue?vue&type=style&index=0&id=16b7b4a6&prod&scoped=true&lang=css"
import style1 from "./shopDetailGenresPage.vue?vue&type=style&index=1&id=16b7b4a6&prod&scoped=true&lang=css"
import style2 from "./shopDetailGenresPage.vue?vue&type=style&index=2&id=16b7b4a6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16b7b4a6",
  null
  
)

export default component.exports