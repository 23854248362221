<template>
  <div class="circle-container">
    <div v-show="showCircle1"
         class="circle circle-1"
         @mouseover="enlargeCircle"
         @mouseout="resetCircle">
      {{ circle1Data }}
    </div>
    <div v-show="showCircle2"
         class="circle circle-2"
         @mouseover="enlargeCircle"
         @mouseout="resetCircle">
      {{ circle2Data }}
    </div>
    <div v-show="showCircle3"
         class="circle circle-3"
         @mouseover="enlargeCircle"
         @mouseout="resetCircle">
      {{ circle3Data }}
    </div>
    <div v-show="showCircle4"
         class="circle circle-4"
         @mouseover="enlargeCircle"
         @mouseout="resetCircle">
      {{ circle4Data }}
    </div>
    <div v-show="showCircle5"
         class="circle circle-5"
         @mouseover="enlargeCircle"
         @mouseout="resetCircle">
      {{ circle5Data }}
    </div>
    <div v-show="showCircle6"
         class="circle circle-6"
         @mouseover="enlargeCircle"
         @mouseout="resetCircle">
      {{ circle6Data }}
    </div>
    <div v-show="showCircle7"
         class="circle circle-7"
         @mouseover="enlargeCircle"
         @mouseout="resetCircle">
      {{ circle7Data }}
    </div>
    <div v-show="showCircle8"
         class="circle circle-8"
         @mouseover="enlargeCircle"
         @mouseout="resetCircle">
      {{ circle8Data }}
    </div>
    <div v-show="showCircle9"
         class="circle circle-9"
         @mouseover="enlargeCircle"
         @mouseout="resetCircle">
      {{ circle9Data }}
    </div>
    <div v-show="showCircle10"
         class="circle circle-10"
         @mouseover="enlargeCircle"
         @mouseout="resetCircle">
      {{ circle10Data }}
    </div>
  </div>
</template>

<script>
export default {

  props: {
    workStyleList: {
      type: [],
      required: true
    }
  },
  watch: {
    workStyleList (newValue) {
      console.log("workStyleList-newValue:", newValue);
      this.hidenAll()

      if (newValue) {
        if (newValue && newValue.length > 10) {
          const lastNineItems = newValue.slice(-10);
          this.showRandomCircle(lastNineItems);
        } else {
          this.showRandomCircle(newValue);
        }
      }
    }
  },

  data () {
    return {
      showCircle1: false,
      showCircle2: false,
      showCircle3: false,
      showCircle4: false,
      showCircle5: false,
      showCircle6: false,
      showCircle7: false,
      showCircle8: false,
      showCircle9: false,
      showCircle10: false,

      circle1Data: '',
      circle2Data: '',
      circle3Data: '',
      circle4Data: '',
      circle5Data: '',
      circle6Data: '',
      circle7Data: '',
      circle8Data: '',
      circle9Data: '',
      circle10Data: '',
    }
  },
  mounted () {
  },

  methods: {
    hidenAll () {
      this.showRandomCircle([]);
      this.showCircle1 = false
      this.showCircle2 = false
      this.showCircle3 = false
      this.showCircle4 = false
      this.showCircle5 = false
      this.showCircle6 = false
      this.showCircle7 = false
      this.showCircle8 = false
      this.showCircle9 = false
      this.showCircle10 = false
    },
    showRandomCircle (list) {
      console.log("showRandomCircle:", list);

      if (list.length == 1) {
        this.showCircle1 = true;
        this.circle1Data = list[0].genreName;
      }

      if (list.length == 2) {
        this.showCircle1 = true;
        this.showCircle6 = true;

        this.circle1Data = list[1].genreName;
        this.circle6Data = list[0].genreName;
      }

      if (list.length == 3) {
        this.circle1Data = list[2].genreName;
        this.showCircle1 = true;

        if (list[0].number == list[1].number) {
          this.showCircle8 = true;
          this.showCircle9 = true;

          this.circle8Data = list[0].genreName;
          this.circle9Data = list[1].genreName;
        } else {
          this.showCircle8 = true;
          this.showCircle3 = true;

          this.circle8Data = list[1].genreName;
          this.circle3Data = list[0].genreName;
        }

      }

      if (list.length == 4) {
        this.showCircle1 = true;
        this.circle1Data = list[3].genreName;

        this.showCircle3 = true;
        this.showCircle7 = true;
        this.showCircle5 = true;

        this.circle3Data = list[0].genreName;
        this.circle5Data = list[1].genreName;
        this.circle7Data = list[2].genreName;

      }

      if (list.length == 5) {
        this.showCircle2 = true;
        this.showCircle4 = true;
        this.showCircle7 = true;
        this.showCircle8 = true;
        this.showCircle1 = true;

        this.circle2Data = list[0].genreName;
        this.circle4Data = list[1].genreName;
        this.circle7Data = list[2].genreName;
        this.circle8Data = list[3].genreName;
        this.circle1Data = list[4].genreName;
      }

      if (list.length == 6) {
        this.showCircle2 = true;
        this.showCircle4 = true;
        this.showCircle7 = true;
        this.showCircle8 = true;
        this.showCircle3 = true;
        this.showCircle1 = true;

        this.circle2Data = list[0].genreName;
        this.circle4Data = list[1].genreName;
        this.circle7Data = list[2].genreName;
        this.circle8Data = list[3].genreName;
        this.circle3Data = list[4].genreName;
        this.circle1Data = list[5].genreName;

      }

      if (list.length == 7) {
        this.showCircle2 = true;
        this.showCircle4 = true;
        this.showCircle7 = true;
        this.showCircle8 = true;
        this.showCircle3 = true;
        this.showCircle9 = true;
        this.showCircle1 = true;

        this.circle2Data = list[0].genreName;
        this.circle4Data = list[1].genreName;
        this.circle7Data = list[2].genreName;
        this.circle8Data = list[3].genreName;
        this.circle3Data = list[4].genreName;
        this.circle9Data = list[5].genreName;
        this.circle1Data = list[6].genreName;
      }

      if (list.length == 8) {
        this.showCircle6 = true;
        this.showCircle2 = true;
        this.showCircle4 = true;
        this.showCircle7 = true;
        this.showCircle8 = true;
        this.showCircle3 = true;
        this.showCircle9 = true;
        this.showCircle1 = true;

        this.circle6Data = list[0].genreName;
        this.circle2Data = list[1].genreName;
        this.circle4Data = list[2].genreName;
        this.circle7Data = list[3].genreName;
        this.circle8Data = list[4].genreName;
        this.circle3Data = list[5].genreName;
        this.circle9Data = list[6].genreName;
        this.circle1Data = list[7].genreName;
      }

      if (list.length == 9) {
        this.showCircle5 = true;
        this.showCircle6 = true;
        this.showCircle2 = true;
        this.showCircle4 = true;
        this.showCircle7 = true;
        this.showCircle8 = true;
        this.showCircle3 = true;
        this.showCircle9 = true;
        this.showCircle1 = true;

        this.circle5Data = list[0].genreName;
        this.circle6Data = list[1].genreName;
        this.circle2Data = list[2].genreName;
        this.circle4Data = list[3].genreName;
        this.circle7Data = list[4].genreName;
        this.circle8Data = list[5].genreName;
        this.circle3Data = list[6].genreName;
        this.circle9Data = list[7].genreName;
        this.circle1Data = list[8].genreName;
      }

      if (list.length == 10) {
        this.showCircle5 = true;
        this.showCircle6 = true;
        this.showCircle2 = true;
        this.showCircle4 = true;
        this.showCircle7 = true;
        this.showCircle8 = true;
        this.showCircle3 = true;
        this.showCircle9 = true;
        this.showCircle1 = true;
        this.showCircle10 = true;

        this.circle5Data = list[0].genreName;
        this.circle10Data = list[1].genreName;
        this.circle6Data = list[2].genreName;
        this.circle2Data = list[3].genreName;
        this.circle4Data = list[4].genreName;
        this.circle7Data = list[5].genreName;
        this.circle8Data = list[6].genreName;
        this.circle3Data = list[7].genreName;
        this.circle9Data = list[8].genreName;
        this.circle1Data = list[9].genreName;
      }
    },
    enlargeCircle (event) {
      event.target.style.transform = "scale(1.2)";
      event.target.style.zIndex = "100";
    },
    resetCircle (event) {
      event.target.style.transform = "scale(1)";
      event.target.style.zIndex = "1";
    }
  }
}
</script>

<style scoped>
.circle-container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: transparent;
}

.circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  color: #fff;
  font-size: 10px;
  font-family: Source Han Sans SC-Regular;
  text-align: center;
  /* word-break: break-word; */
  line-height: 1;
  padding: 2px 2px;
}

.circle-1 {
  top: 35px;
  left: 12vw;
  width: 115px;
  height: 115px;
  z-index: 2;
  background-image: url("@/assets/detail/circle_1.png");
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.circle-2 {
  top: 70px;
  left: 7vw;
  width: 65px;
  height: 65px;
  background-image: url("@/assets/detail/circle_2.png");
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.circle-3 {
  top: 130px;
  left: 10vw;
  width: 55px;
  height: 55px;
  z-index: 10;
  color: rgb(51, 53, 57);
  background-image: url("@/assets/detail/circle_3.png");
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.circle-4 {
  top: 80px;
  left: 23.5vw;
  width: 40px;
  height: 40px;
  background-image: url("@/assets/detail/circle_4.png");
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.circle-5 {
  top: 10px;
  left: 12vw;
  width: 30px;
  height: 30px;
  background-image: url("@/assets/detail/circle_5.png");
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.circle-6 {
  top: 30px;
  left: 20vw;
  width: 45px;
  height: 45px;
  z-index: 1;
  background-image: url("@/assets/detail/circle_6.png");
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.circle-7 {
  top: 120px;
  left: 20vw;
  width: 45px;
  height: 45px;
  z-index: 10;
  background-image: url("@/assets/detail/circle_7.png");
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.circle-8 {
  top: 10px;
  left: 4vw;
  width: 65px;
  height: 65px;
  z-index: 10;
  background-image: url("@/assets/detail/circle_8.png");
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.circle-9 {
  top: 75px;
  left: 0px;
  width: 55px;
  height: 55px;
  z-index: 10;
  background-image: url("@/assets/detail/circle_9.png");
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.circle-10 {
  top: 10px;
  left: 24vw;
  width: 45px;
  height: 45px;
  z-index: 10;
  background-image: url("@/assets/detail/circle_10.png");
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>

<style scoped>
/* PC设备 */
@media only screen and (min-width: 1920px) {
  .circle-container {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: transparent;
  }

  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    left: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
    color: #fff;
    font-size: 10px;
    font-family: Source Han Sans SC-Regular;
    text-align: center;
    /* word-break: break-word; */
    line-height: 1;
    padding: 2px 2px;
  }

  .circle-1 {
    top: 65px;
    left: 240px;
    width: 135px;
    height: 135px;
    z-index: 2;
    background-image: url("@/assets/detail/circle_1.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-2 {
    top: 80px;
    left: 130px;
    width: 85px;
    height: 85px;
    background-image: url("@/assets/detail/circle_2.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-3 {
    top: 140px;
    left: 200px;
    width: 75px;
    height: 75px;
    z-index: 10;
    color: rgb(51, 53, 57);
    background-image: url("@/assets/detail/circle_3.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-4 {
    top: 70px;
    left: 500px;
    width: 105px;
    height: 105px;
    background-image: url("@/assets/detail/circle_4.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-5 {
    top: 10px;
    left: 240px;
    width: 50px;
    height: 50px;
    background-image: url("@/assets/detail/circle_5.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-6 {
    top: 30px;
    left: 380px;
    width: 65px;
    height: 65px;
    z-index: 1;
    background-image: url("@/assets/detail/circle_6.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-7 {
    top: 120px;
    left: 380px;
    width: 65px;
    height: 65px;
    z-index: 10;
    background-image: url("@/assets/detail/circle_7.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-8 {
    top: 20px;
    left: 80px;
    width: 85px;
    height: 85px;
    z-index: 10;
    background-image: url("@/assets/detail/circle_8.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-9 {
    top: 75px;
    left: 0px;
    width: 75px;
    height: 75px;
    z-index: 10;
    background-image: url("@/assets/detail/circle_9.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-10 {
    top: 10px;
    left: 450px;
    width: 65px;
    height: 65px;
    z-index: 10;
    background-image: url("@/assets/detail/circle_10.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>

<style scoped>
/* 移动设备 */
@media only screen and (max-width: 1024px) {
  .circle-container {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: transparent;
  }

  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    left: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
    color: #fff;
    font-size: 8px;
    font-family: Source Han Sans SC-Regular;
    text-align: center;
    /* word-break: break-word; */
    line-height: 1;
    padding: 2px 2px;
  }

  .circle-1 {
    top: 30px;
    left: 60px;
    width: 50px;
    height: 50px;
    z-index: 2;
    background-image: url("@/assets/detail/circle_1.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-2 {
    top: 60px;
    left: 50px;
    width: 40px;
    height: 40px;
    background-image: url("@/assets/detail/circle_2.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-3 {
    top: 40px;
    left: 150px;
    width: 45px;
    height: 45px;
    z-index: 10;
    color: rgb(51, 53, 57);
    background-image: url("@/assets/detail/circle_3.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-4 {
    top: 40px;
    left: 110px;
    width: 45px;
    height: 45px;
    background-image: url("@/assets/detail/circle_4.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-5 {
    top: 0px;
    left: 70px;
    width: 20px;
    height: 20px;
    background-image: url("@/assets/detail/circle_5.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-6 {
    top: 10px;
    left: 100px;
    width: 35px;
    height: 35px;
    z-index: 1;
    background-image: url("@/assets/detail/circle_6.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-7 {
    top: 10px;
    left: 150px;
    width: 35px;
    height: 35px;
    z-index: 10;
    background-image: url("@/assets/detail/circle_7.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-8 {
    top: 2px;
    left: 0px;
    width: 35px;
    height: 35px;
    z-index: 10;
    background-image: url("@/assets/detail/circle_8.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-9 {
    top: 55px;
    left: 0px;
    width: 40px;
    height: 40px;
    z-index: 10;
    background-image: url("@/assets/detail/circle_9.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-10 {
    top: 20px;
    left: 30px;
    width: 35px;
    height: 35px;
    z-index: 10;
    background-image: url("@/assets/detail/circle_10.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>

<style scoped>
/* 移动设备 */
  @media only screen and (min-width: 1024px) and (max-width: 1360px) {
  .circle-container {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: transparent;
    /* background: rgba(255, 0, 0, 0.297); */
    text-align: center;
  }

  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    left: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
    color: #fff;
    font-size: 8px;
    font-family: Source Han Sans SC-Regular;
    text-align: center;
    /* word-break: break-word; */
    line-height: 1;
    padding: 2px 2px;
  }

  .circle-1 {
    top: 20px;
    left: 88px;
    width: 50px;
    height: 50px;
    z-index: 2;
    background-image: url("@/assets/detail/circle_1.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-2 {
    top: 38px;
    left: 50px;
    width: 40px;
    height: 40px;
    background-image: url("@/assets/detail/circle_2.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-3 {
    right: 26px;
    left: auto;
    top: 44px;
    /* left: 150px; */
    width: 45px;
    height: 45px;
    z-index: 10;
    color: rgb(51, 53, 57);
    background-image: url("@/assets/detail/circle_3.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-4 {
    top: 40px;
    /* left: 110px; */
    left: 50%;
    width: 45px;
    height: 45px;
    background-image: url("@/assets/detail/circle_4.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-5 {
    top: 0px;
    left: 70px;
    width: 20px;
    height: 20px;
    background-image: url("@/assets/detail/circle_5.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-6 {
    top: auto;
    bottom: 2px;
    left: 100px;
    width: 35px;
    height: 35px;
    z-index: 1;
    background-image: url("@/assets/detail/circle_6.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-7 {
    top: 5px;
    left: 150px;
    width: 35px;
    height: 35px;
    z-index: 10;
    background-image: url("@/assets/detail/circle_7.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-8 {
    top: 10px;
    left: 186px;
    width: 35px;
    height: 35px;
    z-index: 10;
    background-image: url("@/assets/detail/circle_8.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-9 {
    top: 55px;
    left: 0px;
    width: 40px;
    height: 40px;
    z-index: 10;
    background-image: url("@/assets/detail/circle_9.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .circle-10 {
    top: 20px;
    left: 30px;
    width: 35px;
    height: 35px;
    z-index: 10;
    background-image: url("@/assets/detail/circle_10.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>

