/*
 * shopIndex API  店铺首页
 */

import request from '@/utils/request';

//banner接口
export function getBannerDataApi(params) {
    return request({
      url: `/api/interlib-content/shop/banner_list`,
      method: 'GET',
      params
    });
}

//获取店铺首页theme合集list
export function getSubjectListApi(params) {
  return request({
      url: '/api/interlib-content/shop/subject_list',
      method: 'GET',
      params
  });
}

//获取接待大厅list数据
export function getSaloonListDataApi(data) {
  return request({
      url: '/api/interlib-content/shop/subject_film_list',
      method: 'POST',
      data
  });
}

//获取接待大厅筛选条件areaData数据
export function getSaloonAreaDataApi(params) {
  return request({
      url: '/api/interlib-content/shop/region_list',
      method: 'GET',
      params
  });
}

//获取VIP大厅所需权限数据
export function getVipPowerApi(params) {
  return request({
      url: '/api/interlib-content/shop/is_vip',
      method: 'GET',
      params
  });
}

//获取店铺接待室 申请VIP
export function getApplyVipApi(params) {
  return request({
      url: '/api/interlib-content/shop/apply_vip',
      method: 'GET',
      params
  });
}

//获取店铺信息数据
export function getShopDataApi(params) {
  return request({
      url: '/api/interlib-content/shop/info',
      method: 'GET',
      params
  });
}

//获取店铺详情 留言提交接口
export function handleMessageSubmitApi(params) {
  return request({
      url: '/api/interlib-content/shop/message',
      method: 'GET',
      params
  });
}

