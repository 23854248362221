<template>
  <div ref="fontHtml">
    <div class="shopFooterBox">
      <!-- <div>店铺通用footer</div> -->
      <div class="shopFooterBox_content">
        <div class="content_left">
          <img class="content_left_logo" :src="require('@/assets/shop/icon/footer_logo.png')" alt="">
          <p class="content_text content_left_text content_left_logoSlogan" :class="[{'content_left_logoSlogan_cnStyle':['cn', 'zh'].includes(languageType)}]">{{ $t('shop_footer_logoSlogan') }}</p>
          <p class="content_text content_left_text left_text_two" :class="[{'left_text_two_cnStyle':['cn', 'zh'].includes(languageType)}]">{{ $t('shop_footer_logotext') }}</p>
          <div class="content_left_btn" @click="goOpenShop()">{{ $t('shop_footer_btn') }}</div>
        </div>
        <div class="content_right">
          <div class="content_right_item">
            <div :class="['right_item_title', {'cnStyle': ['cn', 'zh'].includes(languageType)}]">{{ $t('shop_footer_con_oneTitle') }}</div>
            <div class="content_text right_item_text">{{ $t('shop_footer_con_oneText') }}</div>
          </div>
          <div class="content_right_item">
            <div :class="['right_item_title', {'cnStyle': ['cn', 'zh'].includes(languageType)}]">{{ $t('shop_footer_con_twoTitle') }}</div>
            <div class="content_text right_item_text">{{ $t('shop_footer_con_twoText') }}</div>
          </div>
          <div class="content_right_item">
            <div :class="['right_item_title', {'cnStyle': ['cn', 'zh'].includes(languageType)}]">{{ $t('shop_footer_con_threeTitle') }}</div>
            <div class="content_text right_item_text">{{ $t('shop_footer_con_threeText') }}</div>
          </div>
        </div>
      </div>
      <div class="shopFooterBox_foo">{{ $t('shop_footer_AQ') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'shopFooter',
  props: {
    // 语言类型
    languageType: {
      type: String,
      default: 'en',
    },
  },
  data() {
    return {

    }
  },
  methods: {
    // 跳转去 开店
    goOpenShop() {
      // http://qa.interlib.asia/jieshao/ 
      // https://www.interlib.asia/jieshao/
      let lang = localStorage.getItem('locationLanguageType')
      window.open(`https://www.interlib.asia/jieshao?langType=${lang}`, "_blank");
    },
    // 适配方法
    setRem() {
      document.documentElement.style.fontSize = 100 * document.documentElement.clientWidth / 1920 + 'px'
      // this.$refs.fontHtml.style.fontSize = 100 * document.documentElement.clientWidth / 1920 + 'px'
      window.onresize = () => {
        // this.$refs.fontHtml.style.fontSize = 100 * document.documentElement.clientWidth / 1920 + 'px'
          document.documentElement.style.fontSize = 100 * document.documentElement.clientWidth / 1920 + 'px'
      }
    },
    // 清除适配方法-恢复设置根节点的字体大小为默认大小
    clearRem() {
      document.documentElement.style.fontSize = '16px';
      // this.$refs.fontHtml.style.fontSize = '16px'
      window.onresize = () => {
        document.documentElement.style.fontSize = '16px';
        // this.$refs.fontHtml.style.fontSize = '16px'
      }
    },
  },
  mounted() {
    this.setRem()
  },
  destroyed() {
    // 恢复设置根节点的字体大小为默认大小
    this.clearRem()
  },
}
</script>

<style lang="less" scoped>
.shopFooterBox {
  padding: 0 .62rem;
  background: #192B4D;
  font-family: 'Source Han Sans SC-Normal';
  .shopFooterBox_content {
    display: flex;
    justify-content: space-between;
    padding: 1.25rem 0 .9rem;
    .content_text {
      max-width: 2.7rem;
      font-size: .12rem;
      color: #999999;
      line-height: .18rem;
      &.content_left_logoSlogan {
        margin-top: .36rem;
      }
      &.content_left_logoSlogan_cnStyle {
        margin-top: .24rem;
      }
      &.left_text_two {
        // margin-top: .42rem;
      }
      &.left_text_two_cnStyle {
        // margin-top: .3rem;
      }
    }
    .right_item_text {
      line-height: .24rem;
    }
    .content_left {
      width: 30%;
      // margin-top: -0.8rem;
      .content_left_logo {
        width: 2.52rem;
      }
      .content_left_btn {
        width: 2.02rem;
        line-height: .4rem;
        border-radius: .4rem;
        cursor: pointer;
        background: linear-gradient(
          to right,
          #ff036a,
          #d764e0,
          #6e2ff4,
          #1a56fe
        );
        opacity: 0.8;
        font-weight: 500;
        font-size: .14rem;
        color: #FFFFFF;
        text-align: center;
        margin-top: 1rem;
      }
    }
    .content_right {
      width: 70%;
      display: flex;
      justify-content: space-between;
      .content_right_item {
        width: calc( (100% - 2.6rem) / 3 );
        max-width: 3.16rem;
        margin-right: 1.32rem;
        padding-top: .13rem;
        &:last-child {
          margin-right: 0;
        }
        .right_item_title {
          font-family: Source Han Sans SC-Medium;
          font-size: .16rem;
          color: #FFFFFF;
          line-height: .24rem;
          height: 0.48rem;
          margin-bottom: .12rem;
          &.cnStyle {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .shopFooterBox_foo {
    font-size: .12rem;
    color: #FCFCFC;
    line-height: .24rem;
    padding: .46rem .5rem 2.7rem;
    text-align: center;
    border-top: .01rem solid #8e8e8e;
  }
}
</style>

<style lang="less" scoped>
@media only screen and (min-width: 1360px) and (max-width: 1920px) {
  .shopFooterBox {
    padding: 0 .62rem;
    background: #192B4D;
    .shopFooterBox_content {
      display: flex;
      justify-content: space-between;
      padding: 1.25rem 0 .9rem;
      .content_text {
        max-width: 2.7rem;
        font-size: .16rem;
        color: #999999;
        line-height: .24rem;
        &.left_text_two {
          // margin-top: .42rem;
        }
        &.left_text_two_cnStyle {
          // margin-top: .3rem;
        }
      }
      .right_item_text {
        line-height: .24rem;
      }
      .content_left {
        width: 30%;
        // margin-top: -0.8rem;
        .content_left_logo {
          width: 2.52rem;
        }
        .content_left_btn {
          width: 2.02rem;
          line-height: .4rem;
          border-radius: .4rem;
          cursor: pointer;
          background: linear-gradient(
            to right,
            #ff036a,
            #d764e0,
            #6e2ff4,
            #1a56fe
          );
          opacity: 0.8;
          font-weight: 500;
          font-size: .16rem;
          color: #FFFFFF;
          text-align: center;
          margin-top: 1rem;
        }
      }
      .content_right {
        width: 70%;
        display: flex;
        justify-content: space-between;
        .content_right_item {
          width: calc( (100% - 2.6rem) / 3 );
          max-width: 3.16rem;
          margin-right: 1.32rem;
          padding-top: .13rem;
          &:last-child {
            margin-right: 0;
          }
          .right_item_title {
            font-family: Source Han Sans SC-Medium;
            font-size: .18rem;
            color: #FFFFFF;
            line-height: .24rem;
            height: 0.48rem;
            // margin-bottom: .16rem;
            &.cnStyle {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .shopFooterBox_foo {
      font-size: .12rem;
      color: #FCFCFC;
      line-height: .24rem;
      padding: .46rem .5rem 2.7rem;
      text-align: center;
      border-top: .01rem solid #8e8e8e;
    }
  }
}
</style>

<style lang="less" scoped>
@media only screen and (min-width: 1024px) and (max-width: 1360px) {
  .shopFooterBox {
    padding: 0 .62rem;
    background: #192B4D;
    .shopFooterBox_content {
      display: flex;
      justify-content: space-between;
      padding: 1.25rem 0 .9rem;
      .content_text {
        max-width: 2.7rem;
        font-size: .16rem;
        color: #999999;
        line-height: .24rem;
        &.left_text_two {
          // margin-top: .42rem;
        }
        &.left_text_two_cnStyle {
          // margin-top: .3rem;
        }
      }
      .right_item_text {
        line-height: .24rem;
      }
      .content_left {
        width: 30%;
        // margin-top: -0.8rem;
        .content_left_logo {
          width: 2.52rem;
        }
        .content_left_btn {
          width: 2.02rem;
          line-height: .4rem;
          border-radius: .4rem;
          cursor: pointer;
          background: linear-gradient(
            to right,
            #ff036a,
            #d764e0,
            #6e2ff4,
            #1a56fe
          );
          opacity: 0.8;
          font-weight: 500;
          font-size: .16rem;
          color: #FFFFFF;
          text-align: center;
          margin-top: 1rem;
        }
      }
      .content_right {
        width: 70%;
        display: flex;
        justify-content: space-between;
        .content_right_item {
          width: calc( (100% - 2.6rem) / 3 );
          max-width: 3.16rem;
          margin-right: 1.32rem;
          padding-top: .13rem;
          &:last-child {
            margin-right: 0;
          }
          .right_item_title {
            font-family: Source Han Sans SC-Medium;
            font-size: .18rem;
            color: #FFFFFF;
            line-height: .24rem;
            height: 0.48rem;
            // margin-bottom: .16rem;
            &.cnStyle {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .shopFooterBox_foo {
      font-size: .12rem;
      color: #FCFCFC;
      line-height: .24rem;
      padding: .46rem .5rem 2.7rem;
      text-align: center;
      border-top: .01rem solid #8e8e8e;
    }
  }
}
</style>


