import { render, staticRenderFns } from "./CreatCollectPop.vue?vue&type=template&id=4cd42676&scoped=true"
import script from "./CreatCollectPop.vue?vue&type=script&lang=js"
export * from "./CreatCollectPop.vue?vue&type=script&lang=js"
import style0 from "./CreatCollectPop.vue?vue&type=style&index=0&id=4cd42676&prod&scoped=true&lang=css"
import style1 from "./CreatCollectPop.vue?vue&type=style&index=1&id=4cd42676&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cd42676",
  null
  
)

export default component.exports