<!-- DetailProductionCompanyPage -->
<template>
  <div class="detail-item-page-contaner">
    <div class="detail-item-page-contaner-left-container">
      <div class="detail-item-page-contaner-left-container-bottom-container">
        <div class="detail-item-page-contaner-left-container-bottom-container-info">
          <div class="detail-item-page-contaner-left-container-bottom-container-info-img">
            <img v-if="newSelectCastItem.imgUrl"
                 :src="newSelectCastItem.imgUrl"
                 alt="">
            <img v-else-if="newSelectCastItem"
                 :src="avatar"
                 alt="">
          </div>
          <div class="detail-item-page-contaner-left-container-bottom-container-info-container">
            <div class="detail-item-page-contaner-left-container-bottom-container-info-name">
              {{ productionCompanyData?.name }}
            </div>
            <div class="detail-item-page-contaner-left-container-bottom-container-info-introduction">
              {{productionCompanyData?.introduction }}
            </div>
          </div>
        </div>
        <div class="detail-item-page-contaner-left-container-bottom-container-info-tips">
          <div v-show="companyDetailTips1.length > 0"
               class="detail-item-page-contaner-left-container-bottom-container-info-tips-item">
            <img src="@/assets/detail/genres.jpg"
                 alt="">
            <span>{{ companyDetailTips1 }}</span>
          </div>
          <div v-show="companyDetailTips2.length > 0"
               class="detail-item-page-contaner-left-container-bottom-container-info-tips-item">
            <img src="@/assets/detail/genres.jpg"
                 alt="">
            <span>{{ companyDetailTips2 }}</span>
          </div>
          <div v-show="companyDetailTips3.length > 0"
               class="detail-item-page-contaner-left-container-bottom-container-info-tips-item">
            <img src="@/assets/detail/genres.jpg"
                 alt="">
            <span>{{ companyDetailTips3 }}</span>
          </div>

        </div>
        <div class="detail-item-page-contaner-left-container-bottom-container-discribe"
             style="white-space: pre-line;">
          {{productionCompanyData?.detail}}
        </div>
      </div>
    </div>
    <div class="detail-item-page-contaner-right-container">
      <div class="detail-item-page-contaner-right-container-masterpiece-container">
        <div :class="{
        'detail-item-page-contaner-right-container-masterpiece-item-select': bannerCurrentIndex === 0,
        'detail-item-page-contaner-right-container-masterpiece-item-normal': bannerCurrentIndex === 1
              }"
             v-show="showMasterpiece"
             @click="bannerHandleClick(0)">
          {{$t('detail_masterpiece')}}
        </div>
        <div :class="{
        'detail-item-page-contaner-right-container-masterpiece-item-select': bannerCurrentIndex === 1,
        'detail-item-page-contaner-right-container-masterpiece-item-normal': bannerCurrentIndex === 0
              }"
             v-show="showUpcoming"
             @click="bannerHandleClick(1)">
          {{$t('detail_upcoming')}}
        </div>
      </div>

      <div class="detail-item-page-contaner-right-container-masterpiece-container-scroll-container"
           v-show="dataList && dataList.length > 0">
        <div class="genres-right-container-bottom-grid-container">
          <!-- 制片公司下代表作 -->
          <div class="genres-right-container-bottom-grid-item"
               v-for="item in dataList"
               :key="item.id"
               @click="gotoDetail(item)"
               :style="{ backgroundImage: 'url(' + item.imageUrl + ')' }">
            <div class="recommend-container-top-detail">
              <span class="recommend-container-top-detail-movies-title">{{  languageType == 'en' ? item.projectNameEn : item.projectName }}</span>
              <span class="recommend-container-top-detail-movies-detail">{{ item.genres.map(genre => genre.name).join(',') }}</span>
            </div>
            <div class="top-collect">
              <!-- <img :src="item.collectionFlag == 1 ? require('@/assets/home/collect-select.jpg') : require('@/assets/home/collect-normal.jpg') "
                   alt=""
                   @click="topCollectClick(item, $event)"> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DetailMenuPage from '@/views/Detail/New/DetailMenuPage.vue'

import { productionCompanyDetailApi, } from '@/api/detailApi.js'
import { getPresignedUrlApi } from '@/api/homeApi.js'
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    menuPage: DetailMenuPage,
  },
  computed: {
    ...mapState('collectDialog', ['projectId']),
    ...mapState('deatilEra', ['eraId']),
    ...mapState('auth', ['isLoggedIn'])
  },
  props: {
    detailPageData: {
      type: Object,
      required: true
    },

    selectCastItem: {
      type: Object,
      required: true
    }
  },
  created () {
    var mediaQuery = window.matchMedia('(max-width: 1024px)');
    if (mediaQuery.matches) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.reloadLocationLanguageData()

    this.$store.watch(
      (state) => state.collectDialog.hasCollectSuccess,
      (newVal) => {
        if (newVal) {
          if (this.selectItem.id == this.projectId) {
            console.log('DetailPageTrailersCast-Recommend-hasCollectSuccess:', newVal);

            this.dataList = this.dataList.map(item => {
              if (item.id === this.selectItem.id) {
                console.log('Recommend-匹配数据:', item.id === this.selectItem.id);
                item.collectionFlag = 1;
              }
              return item;
            });
            this.setCollectNormalDialog()
          }
        }
      }
    );
    // this.handleDetailPageData();
    this.fetchDetailData(this.selectCastItem);
  },

  watch: {
    detailPageData (newValue) {
      if (newValue) {
        console.log("DetailCastPage-detailPageData:", newValue);
      }
    },
    selectCastItem (newValue) {
      if (newValue) {
        console.log("DetailProductionCompanyPage-selectedItem:", newValue);
        this.resetData()
        this.newSelectCastItem = newValue;
        this.fetchDetailData(newValue);
      }
    }
  },

  data () {
    return {
      newSelectCastItem: undefined,
      avatar: require('@/assets/detail/company_default_head.jpg'),

      newCastsList: [],
      newSelectedItem: undefined,
      productionCompanyData: {},
      showMasterpiece: false,
      showUpcoming: false,
      bannerCurrentIndex: 0, //banner菜单栏
      dataList: [],
      masterpieceBannerList: [],
      upcomingBannerList: [],

      selectItem: {},
      newRelatedList: [],
      languageType: "",
      isMobile: false,
      companyDetailTips1: '',
      companyDetailTips2: '',
      companyDetailTips3: '',
    }
  },
  methods: {
    ...mapActions('collectDialog', ['showCollectOfWorksDialog', 'setProjectIdDialog', 'hasCollectSuccess', 'setCollectNormalDialog']),
    ...mapActions('dialog', ['showLoginDialog']),

    reloadLocationLanguageData () {
      // 检查本地存储中的语言类型
      const language = localStorage.getItem('locationLanguageType')

      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
    },

    handleDetailPageData () {
      this.handleDetailData(this.detailPageData.productionCompanies)
    },

    async handleDetailData (casts) {
      if (casts && casts.length > 0) {
        //获取头像图片URL
        const resultData = await Promise.all(
          casts.map(async (element) => {
            if (element) {
              if (element.mode == 1) {//mode 文件访问模式 1:fileId  2:imgUrl链接 null:没有图片
                try {
                  const imageRes = await getPresignedUrlApi(element.fileId);
                  if (imageRes.data.code === 200) {
                    element.imgUrl = imageRes.data.data.url;
                  }
                } catch (error) {
                  // 错误处理
                }
              }
            }
            return element; // 返回更新后的对象
          })
        );
        if (resultData.filter(Boolean).length > 0) {
          this.newCastsList = resultData.filter(Boolean);
        }
        console.log("处理后的演员头像数据：", this.newCastsList);
      }
    },

    resetData () {
      this.newSelectCastItem = undefined
      this.productionCompanyData = {}
      this.showMasterpiece = false
      this.showUpcoming = false
      this.bannerCurrentIndex = 0
      this.dataList = []
      this.masterpieceBannerList = []
      this.upcomingBannerList = []
      this.selectItem = {}
      this.companyDetailTips1 = ''
      this.companyDetailTips2 = ''
      this.companyDetailTips3 = ''
    },
    gotoDetail (item) {
      return false;
      // 获取域名
      const url = new URL(window.location.href);
      const domain = url.hostname;
      console.log("url:", url);
      console.log("domain:", domain);

      // 判断域名是否符合预期
      if (domain === 'www.interlib.asia') {
        // 线上地址
        console.log("生产环境");
        const host = 'https://www.interlib.asia/';
        const nameEn = item.projectNameEn ? item.projectNameEn : 'interlib'
        const enName = nameEn.replace(/\s+/g, '-');
        const languagePrefix = this.languageType === 'en' ? 'en/' : 'cn/';
        const url = `${host}${languagePrefix}${item.id}-${enName}`;
        console.log('生产环境url:', url);
        // 在新标签页打开
        window.open(url, '_blank');
      } else if (domain === 'qa.interlib.asia') {
        // 测试地址
        console.log("测试环境");
        const host = 'http://qa.interlib.asia/';
        const nameEn = item.projectNameEn ? item.projectNameEn : 'interlib'
        const enName = nameEn.replace(/\s+/g, '-');
        const languagePrefix = this.languageType === 'en' ? 'en/' : 'cn/';
        const url = `${host}${languagePrefix}${item.id}-${enName}`;
        console.log('测试环境url:', url);
        // 在新标签页打开
        window.open(url, '_blank');
      } else {
        // 本地地址
        console.log("本地环境");
        const host = 'http://localhost:8087/';
        const nameEn = item.projectNameEn ? item.projectNameEn : 'interlib'
        const enName = nameEn.replace(/\s+/g, '-');
        const languagePrefix = this.languageType === 'en' ? 'en/' : 'cn/';
        const url = `${host}${languagePrefix}${item.id}-${enName}`;
        console.log('本地环境url:', url);
        // 在新标签页打开
        window.open(url, '_blank');
      }

    },

    topCollectClick (item, event) {
      // 阻止事件冒泡到父元素的点击事件
      event.stopPropagation();
      if (this.isLoggedIn) {
        this.selectItem = item

        console.log("topCollectClick:", item.id);
        this.showCollectOfWorksDialog()
        this.setProjectIdDialog(item.id)
        console.log("projectId:", this.projectId);
      } else {
        this.showLoginDialog()
      }

    },
    bannerClick (id, event) {
      // 阻止事件冒泡到父元素的点击事件
      event.stopPropagation();
      console.log("projectId:", id);

      // 在详情页中再次导航到另一个详情页
      const targetPath = '/detail/' + id;
      console.log("targetPath:", targetPath);
      console.log("this.$route.path:", this.$route.path);
      if (this.$route.path !== targetPath) {
        this.$router.push(targetPath);
      }
    },
    bannerHandleClick (index) {
      this.bannerCurrentIndex = index
      console.log("bannerHandleClick:", index);
      if (index == 0) {
        this.dataList = this.masterpieceBannerList
      } else {
        this.dataList = this.upcomingBannerList
      }
      if (this.dataList && this.dataList.length > 6) {
        const newDataList = this.dataList.slice(0, 6);
        this.dataList = newDataList;
      }

    },
    handleSelectItemData (newValue) {
      console.log("handleSelectItemData:", newValue);
      this.fetchDetailData(newValue.name);
    },

    //获取制片公司数据
    async fetchDetailData (item) {
      console.log("获取制片公司数据item:", item);

      const data = {
        name: item.name,
        language: this.languageType
      };
      try {
        const res = await productionCompanyDetailApi(data);
        if (res.data.code === 200) {
          const resultData = res.data.data;
          console.log("获取制片公司数据:", resultData);
          this.productionCompanyData = resultData;

          if (this.productionCompanyData.tips) {
            const detailTips = this.productionCompanyData.tips.split(',');
            if (detailTips && detailTips.length > 0) {
              this.companyDetailTips1 = detailTips[0]
            }
            if (detailTips && detailTips.length > 1) {
              this.companyDetailTips2 = detailTips[1]
            }
            if (detailTips && detailTips.length > 2) {
              this.companyDetailTips3 = detailTips[2]
            }
          }

          //Masterpiece
          const masterpieceBannerListDataResultData = await Promise.all(
            resultData.masterpieceList.map(async (element) => {
              try {
                const imageRes = await getPresignedUrlApi(element.imageId[0]);
                if (imageRes.data.code === 200) {
                  element.imageUrl = imageRes.data.data.url;
                  return element;
                }
              } catch (error) {
                // 错误处理
              }
            })
          );
          this.masterpieceBannerList = masterpieceBannerListDataResultData.filter(Boolean); // 过滤掉可能为 undefined 的元素
          console.log("获取主演-Masterpiece-banner数据:", this.masterpieceBannerList);

          //Upcoming
          const upcomingBannerListDataResultData = await Promise.all(
            resultData.upcomingList.map(async (element) => {
              try {
                const imageRes = await getPresignedUrlApi(element.imageId[0]);
                if (imageRes.data.code === 200) {
                  element.imageUrl = imageRes.data.data.url;
                  return element;
                }
              } catch (error) {
                // 错误处理
              }
            })
          );
          this.upcomingBannerList = upcomingBannerListDataResultData.filter(Boolean); // 过滤掉可能为 undefined 的元素
          console.log("获取主演-Upcoming-banner数据:", this.upcomingBannerList);

          if (this.masterpieceBannerList && this.masterpieceBannerList.length > 0) {
            this.showMasterpiece = true
          } else {
            this.showMasterpiece = false
          }
          if (this.upcomingBannerList && this.upcomingBannerList.length > 0) {
            this.showUpcoming = true
          } else {
            this.showUpcoming = false
          }

          if (this.masterpieceBannerList && this.masterpieceBannerList.length > 0) {
            this.dataList = this.masterpieceBannerList
          } else {
            this.dataList = this.upcomingBannerList
          }

          if (this.dataList && this.dataList.length > 6) {
            const newDataList = this.dataList.slice(0, 6);
            this.dataList = newDataList;
          }
        }
      } catch (error) {
        // 处理错误，可以在这里记录日志或者进行其他处理
      }
    },
  }
}
</script>

<style scoped>
.detail-item-page-contaner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.detail-item-page-contaner-left-container {
  width: 51%;
  height: 100%;
}

.detail-item-page-contaner-left-container-top-container {
  width: 95%;
  height: 22%;
}

.detail-item-page-contaner-left-container-bottom-container {
  height: 70%;
  margin-left: 5%;
  margin-right: 5%;
}

.detail-item-page-contaner-left-container-bottom-container-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
}

.detail-item-page-contaner-left-container-bottom-container-info-img {
  width: 70px;
  height: 70px;
}

.detail-item-page-contaner-left-container-bottom-container-info-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.detail-item-page-contaner-left-container-bottom-container-info-container {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.detail-item-page-contaner-left-container-bottom-container-info-name {
  color: #f5f5f5;
  font-size: 18px;
  font-family: Source Han Sans SC-Medium;
  margin-right: 0;
  white-space: nowrap;
}

.detail-item-page-contaner-left-container-bottom-container-info-introduction {
  color: #a8a8a8;
  font-size: 14px;
  font-family: Source Han Sans SC-Regular;
  margin-right: 5%;
}

.detail-item-page-contaner-left-container-bottom-container-info-tips {
  color: #f5f5f5;
  font-size: 16px;
  font-family: Source Han Sans SC-Medium;
  margin-right: 2.5%;
  margin-top: 30px;
  white-space: nowrap;
  height: 10%;
  display: flex;
  flex-direction: row;
}

.detail-item-page-contaner-left-container-bottom-container-info-tips-item {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.detail-item-page-contaner-left-container-bottom-container-info-tips-item img {
  width: 20px;
  height: auto;
  margin-right: 3%;
}

.detail-item-page-contaner-left-container-bottom-container-info-tips-item span {
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Regular;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail-item-page-contaner-left-container-bottom-container-discribe {
  margin-top: 30px;
  overflow: auto;
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
  line-height: 2;
  height: 280px;
}

.detail-item-page-contaner-right-container {
  width: 48%;
  height: 92%;
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  margin-top: 30px;
  margin-right: 20px;
}

.detail-item-page-contaner-right-container-masterpiece-container {
  display: flex;
  flex-direction: row;
}

.detail-item-page-contaner-right-container-masterpiece-item-normal {
  color: #f5f5f5;
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
  border: 1px solid #fff;
  width: 100px;
  height: 22px;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  margin-right: 5%;
  cursor: pointer;
  padding-bottom: 3px;
}

.detail-item-page-contaner-right-container-masterpiece-item-select {
  color: #f5f5f5;
  font-size: 14px;
  font-family: Source Han Sans SC-Medium;
  background-image: linear-gradient(
    to right,
    #ff036a,
    #d764e0,
    #6e2ff4,
    #1a56fe
  );
  width: 100px;
  height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  margin-right: 5%;
  cursor: pointer;
  padding-bottom: 3px;
}

.detail-item-page-contaner-right-container-masterpiece-container-scroll-container {
  margin-top: 25px;
  height: 390px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.genres-right-container-bottom-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 5%;
  width: 100%;
  height: 100%;
  padding: 0px;
}

.genres-right-container-bottom-grid-item {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  position: relative;
  /* cursor: pointer; */
  transition: transform 0.5s ease;
}

.genres-right-container-bottom-grid-item:hover {
  /* transform: translateY(-5px); */
}

.genres-right-container-bottom-grid-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
}

.genres-right-container-bottom-grid-item:hover::before {
  /* opacity: 1; */
}

.genres-right-container-bottom-grid-item:hover
  .recommend-container-top-detail
  span {
  /* animation: moveUp 0.5s ease forwards; */
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

.recommend-container-top-detail {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0)
  ); /* 渐变背景 */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.recommend-container-top-detail-movies-title {
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Medium;
  line-height: 1.1;
  margin-left: 10px;
  margin-right: 10px;
  white-space: pre-wrap;
}

.recommend-container-top-detail-movies-detail {
  margin-top: 4px;
  color: #9b9b9b;
  font-size: 12px;
  font-family: Source Han Sans SC-Normal;
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 8vw;
  margin-bottom: 15%;
}

.top-collect {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 10px 10px;
  z-index: 100;
}

.top-collect img {
  border-radius: 0;
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  background-color: transparent;
}

/* 整个滚动条 */
::-webkit-scrollbar {
  width: 2px;
  border-radius: 3px;
  background-color: transparent;
  /* 隐藏水平滚动条 */
  height: 0;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #f1f1f1;
  border-radius: 6px;
}
</style>

<style scoped>
/* PC设备 */
@media only screen and (min-width: 1920px) {
  .detail-item-page-contaner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .detail-item-page-contaner-left-container {
    width: calc(100% - 640px);
    height: 100%;
  }

  .detail-item-page-contaner-left-container-top-container {
    width: 95%;
    height: 22%;
  }

  .detail-item-page-contaner-left-container-bottom-container {
    height: 70%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .detail-item-page-contaner-left-container-bottom-container-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-img {
    width: 70px;
    height: 70px;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-container {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-name {
    color: #f5f5f5;
    font-size: 18px;
    font-family: Source Han Sans SC-Medium;
    margin-right: 0;
    white-space: nowrap;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-introduction {
    color: #a8a8a8;
    font-size: 14px;
    font-family: Source Han Sans SC-Regular;
    margin-right: 5%;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-tips {
    color: #f5f5f5;
    font-size: 16px;
    font-family: Source Han Sans SC-Medium;
    margin-right: 2.5%;
    margin-top: 30px;
    white-space: nowrap;
    height: 10%;
    display: flex;
    flex-direction: row;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-tips-item {
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-tips-item
    img {
    width: 20px;
    height: auto;
    margin-right: 3%;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-tips-item
    span {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Regular;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .detail-item-page-contaner-left-container-bottom-container-discribe {
    margin-top: 30px;
    overflow: auto;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    line-height: 2;
    height: 280px;
  }

  .detail-item-page-contaner-right-container {
    width: 600px;
    height: 92%;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-top: 30px;
    margin-right: 20px;
  }

  .detail-item-page-contaner-right-container-masterpiece-container {
    display: flex;
    flex-direction: row;
  }

  .detail-item-page-contaner-right-container-masterpiece-item-normal {
    color: #f5f5f5;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    border: 1px solid #fff;
    width: 100px;
    height: 22px;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-right: 5%;
    cursor: pointer;
    padding-bottom: 3px;
  }

  .detail-item-page-contaner-right-container-masterpiece-item-select {
    color: #f5f5f5;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    background-image: linear-gradient(
      to right,
      #ff036a,
      #d764e0,
      #6e2ff4,
      #1a56fe
    );
    width: 100px;
    height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-right: 5%;
    cursor: pointer;
    padding-bottom: 3px;
  }

  .detail-item-page-contaner-right-container-masterpiece-container-scroll-container {
    margin-top: 25px;
    height: 490px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }

  .genres-right-container-bottom-grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 30px;
    width: 100%;
    height: 100%;
    padding: 0px;
  }

  .genres-right-container-bottom-grid-item {
    height: 100%;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
    position: relative;
    /* cursor: pointer; */
    transition: transform 0.5s ease;
  }

  .genres-right-container-bottom-grid-item:hover {
    /* transform: translateY(-5px); */
  }

  .genres-right-container-bottom-grid-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 8px;
  }

  .genres-right-container-bottom-grid-item:hover::before {
    /* opacity: 1; */
  }

  .genres-right-container-bottom-grid-item:hover
    .recommend-container-top-detail
    span {
    /* animation: moveUp 0.5s ease forwards; */
  }

  @keyframes moveUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-5px);
    }
  }

  .recommend-container-top-detail {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0)
    ); /* 渐变背景 */
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .recommend-container-top-detail-movies-title {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    line-height: 1.1;
    margin-left: 10px;
    margin-right: 10px;
    white-space: pre-wrap;
  }

  .recommend-container-top-detail-movies-detail {
    margin-top: 4px;
    color: #9b9b9b;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    line-height: 1.3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 8vw;
    margin-bottom: 15%;
  }

  .top-collect {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px 10px;
    z-index: 100;
  }

  .top-collect img {
    border-radius: 0;
    width: 100%;
    height: 100%;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 3px;
    background-color: transparent;
    /* 隐藏水平滚动条 */
    height: 0;
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
}
</style>

<style scoped>
/* 移动设备 */
@media only screen and (max-width: 1024px) {
  .detail-item-page-contaner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .detail-item-page-contaner-left-container {
    width: calc(100% - 270px);
    height: 100%;
  }

  .detail-item-page-contaner-left-container-top-container {
    width: 95%;
    height: 22%;
  }

  .detail-item-page-contaner-left-container-bottom-container {
    height: 70%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .detail-item-page-contaner-left-container-bottom-container-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-img {
    width: 50px;
    height: 50px;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-container {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-name {
    color: #f5f5f5;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    margin-right: 0;
    white-space: nowrap;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-introduction {
    color: #a8a8a8;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    margin-right: 5px;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-tips {
    color: #f5f5f5;
    font-size: 12px;
    font-family: Source Han Sans SC-Medium;
    margin-right: 2.5%;
    margin-top: 10px;
    white-space: nowrap;
    height: 10%;
    display: flex;
    flex-direction: row;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-tips-item {
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-tips-item
    img {
    width: 14px;
    height: auto;
    margin-right: 3%;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-tips-item
    span {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Regular;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .detail-item-page-contaner-left-container-bottom-container-discribe {
    margin-top: 10px;
    overflow: auto;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    line-height: 2;
    height: 280px;
  }

  .detail-item-page-contaner-right-container {
    width: 250px;
    height: 370px;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-top: 15px;
    margin-right: 20px;
  }

  .detail-item-page-contaner-right-container-masterpiece-container {
    display: flex;
    flex-direction: row;
  }

  .detail-item-page-contaner-right-container-masterpiece-item-normal {
    color: #f5f5f5;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    border: 1px solid #fff;
    width: 90px;
    height: 20px;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-right: 5%;
    cursor: pointer;
    padding-bottom: 3px;
  }

  .detail-item-page-contaner-right-container-masterpiece-item-select {
    color: #f5f5f5;
    font-size: 12px;
    font-family: Source Han Sans SC-Medium;
    background-image: linear-gradient(
      to right,
      #ff036a,
      #d764e0,
      #6e2ff4,
      #1a56fe
    );
    width: 90px;
    height: 22px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-right: 5%;
    cursor: pointer;
    padding-bottom: 3px;
  }

  .detail-item-page-contaner-right-container-masterpiece-container-scroll-container {
    margin-top: 20px;
    height: 330px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }

  .genres-right-container-bottom-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 15px;
    width: 100%;
    height: 100%;
    padding: 0px;
  }

  .genres-right-container-bottom-grid-item {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
    position: relative;
    /* cursor: pointer; */
    transition: transform 0.5s ease;
  }

  .genres-right-container-bottom-grid-item:hover {
    /* transform: translateY(-5px); */
  }

  .genres-right-container-bottom-grid-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 8px;
  }

  .genres-right-container-bottom-grid-item:hover::before {
    /* opacity: 1; */
  }

  .genres-right-container-bottom-grid-item:hover
    .recommend-container-top-detail
    span {
    /* animation: moveUp 0.5s ease forwards; */
  }

  @keyframes moveUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-5px);
    }
  }

  .recommend-container-top-detail {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0)
    ); /* 渐变背景 */
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .recommend-container-top-detail-movies-title {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    line-height: 1.1;
    margin-left: 10px;
    margin-right: 10px;
    white-space: pre-wrap;
  }

  .recommend-container-top-detail-movies-detail {
    margin-top: 4px;
    color: #9b9b9b;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    line-height: 1.3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 8vw;
    margin-bottom: 15%;
  }

  .top-collect {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px 10px;
    z-index: 100;
  }

  .top-collect img {
    border-radius: 0;
    width: 100%;
    height: 100%;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 3px;
    background-color: transparent;
    /* 隐藏水平滚动条 */
    height: 0;
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
}
</style>

<style scoped>
/* 移动设备 */
@media only screen and (min-width: 1024px) and (max-width: 1360px) {
  .detail-item-page-contaner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .detail-item-page-contaner-left-container {
    width: calc(100% - 270px);
    height: 100%;
  }

  .detail-item-page-contaner-left-container-top-container {
    width: 95%;
    height: 22%;
  }

  .detail-item-page-contaner-left-container-bottom-container {
    height: 70%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .detail-item-page-contaner-left-container-bottom-container-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-img {
    width: 50px;
    height: 50px;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-container {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-name {
    color: #f5f5f5;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    margin-right: 0;
    white-space: nowrap;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-introduction {
    color: #a8a8a8;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    margin-right: 5px;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-tips {
    color: #f5f5f5;
    font-size: 12px;
    font-family: Source Han Sans SC-Medium;
    margin-right: 2.5%;
    margin-top: 10px;
    white-space: nowrap;
    height: 10%;
    display: flex;
    flex-direction: row;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-tips-item {
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-tips-item
    img {
    width: 14px;
    height: auto;
    margin-right: 3%;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-tips-item
    span {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Regular;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .detail-item-page-contaner-left-container-bottom-container-discribe {
    margin-top: 10px;
    overflow: auto;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    line-height: 2;
    height: 280px;
  }

  .detail-item-page-contaner-right-container {
    width: 250px;
    height: 370px;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-top: 15px;
    margin-right: 20px;
  }

  .detail-item-page-contaner-right-container-masterpiece-container {
    display: flex;
    flex-direction: row;
  }

  .detail-item-page-contaner-right-container-masterpiece-item-normal {
    color: #f5f5f5;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    border: 1px solid #fff;
    width: 90px;
    height: 20px;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-right: 5%;
    cursor: pointer;
    padding-bottom: 3px;
  }

  .detail-item-page-contaner-right-container-masterpiece-item-select {
    color: #f5f5f5;
    font-size: 12px;
    font-family: Source Han Sans SC-Medium;
    background-image: linear-gradient(
      to right,
      #ff036a,
      #d764e0,
      #6e2ff4,
      #1a56fe
    );
    width: 90px;
    height: 22px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-right: 5%;
    cursor: pointer;
    padding-bottom: 3px;
  }

  .detail-item-page-contaner-right-container-masterpiece-container-scroll-container {
    margin-top: 20px;
    height: 330px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }

  .genres-right-container-bottom-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 15px;
    width: 100%;
    height: 100%;
    padding: 0px;
  }

  .genres-right-container-bottom-grid-item {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
    position: relative;
    /* cursor: pointer; */
    transition: transform 0.5s ease;
  }

  .genres-right-container-bottom-grid-item:hover {
    /* transform: translateY(-5px); */
  }

  .genres-right-container-bottom-grid-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 8px;
  }

  .genres-right-container-bottom-grid-item:hover::before {
    /* opacity: 1; */
  }

  .genres-right-container-bottom-grid-item:hover
    .recommend-container-top-detail
    span {
    /* animation: moveUp 0.5s ease forwards; */
  }

  @keyframes moveUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-5px);
    }
  }

  .recommend-container-top-detail {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0)
    ); /* 渐变背景 */
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .recommend-container-top-detail-movies-title {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    line-height: 1.1;
    margin-left: 10px;
    margin-right: 10px;
    white-space: pre-wrap;
  }

  .recommend-container-top-detail-movies-detail {
    margin-top: 4px;
    color: #9b9b9b;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    line-height: 1.3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 8vw;
    margin-bottom: 15%;
  }

  .top-collect {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px 10px;
    z-index: 100;
  }

  .top-collect img {
    border-radius: 0;
    width: 100%;
    height: 100%;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 3px;
    background-color: transparent;
    /* 隐藏水平滚动条 */
    height: 0;
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
}
</style>
