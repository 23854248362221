import { render, staticRenderFns } from "./shopDetailPage.vue?vue&type=template&id=16c108ba&scoped=true"
import script from "./shopDetailPage.vue?vue&type=script&lang=js"
export * from "./shopDetailPage.vue?vue&type=script&lang=js"
import style0 from "./shopDetailPage.vue?vue&type=style&index=0&id=16c108ba&prod&lang=less&scoped=true"
import style1 from "./shopDetailPage.vue?vue&type=style&index=1&id=16c108ba&prod&scoped=true&lang=css"
import style2 from "./shopDetailPage.vue?vue&type=style&index=2&id=16c108ba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16c108ba",
  null
  
)

export default component.exports