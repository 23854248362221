<!-- DetailInfluencePage -->
<template>
  <div class="detail-influence-page-contaner">
    <div class="influence-left-arrow"
         v-show="showLeftScroll">
      <img class="influence-left-scroll"
           src="@/assets/detail/right.png"
           @click="influenceSlideLeft"
           alt="" />
    </div>
    <div class="influence-container"
         v-show="tempInfluenceList && tempInfluenceList.length > 0">
      <div class="influence-container-item"
           v-for="(influence, index) in tempInfluenceList"
           :key="index">
        <div class="influence-container-item-container">
          <div class="influence-container-item-container-img">
            <img src="@/assets/detail/influence-item-icon.png"
                 alt="">
            <div class="influence-container-item-title">
              {{ influence?.name }}
            </div>
          </div>

          <div class="influence-container-item-detail">
            {{ influence?.awards }}
          </div>
        </div>
      </div>
    </div>
    <div class="influence-right-arrow"
         v-show="showRightScroll">
      <img class="influence-right-scroll"
           src="@/assets/detail/left.png"
           @click="influenceSlideRight"
           alt="" />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    influenceListData: {
      type: [],
      required: true
    },
  },
  created () {
    var mediaQuery = window.matchMedia('(max-width: 1024px)');
    if (mediaQuery.matches) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.reloadLocationLanguageData()
  },

  watch: {
    influenceListData (newVal) {
      if (newVal && newVal.length > 0) {
        this.tempInfluenceList = newVal
        setTimeout(() => {
          this.updateScrollButtonVisibility();
        }, 2500);
      } else {
        console.log('relateList is empty or undefined');
      }
    }
  },

  data () {
    return {
      tempInfluenceList: [],
      languageType: "",
      isMobile: false,
      showLeftScroll: false,
      showRightScroll: false,
    }
  },
  methods: {
    reloadLocationLanguageData () {
      const language = localStorage.getItem('locationLanguageType')
      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
    },

    resetData () {
      this.tempInfluenceList = []
    },

    influenceSlideLeft () {
      const container = document.querySelector('.influence-container');
      const itemWidth = container.offsetWidth;
      const scrollAmount = itemWidth;

      container.scrollBy({
        left: -scrollAmount,
        behavior: 'smooth'
      });
      setTimeout(this.updateScrollButtonVisibility, 500);
    },

    influenceSlideRight () {
      const container = document.querySelector('.influence-container');
      const itemWidth = container.offsetWidth;
      const scrollAmount = itemWidth;

      container.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      });
      setTimeout(this.updateScrollButtonVisibility, 500);
    },
    updateScrollButtonVisibility () {
      const container = document.querySelector('.influence-container');
      const maxScrollLeft = container?.scrollWidth - container?.clientWidth;
      this.showLeftScroll = container?.scrollLeft > 0;
      this.showRightScroll = (container?.scrollLeft + 30) < maxScrollLeft;
    },

  },
  mounted () {
    const container = document.querySelector('.influence-container');
    container.addEventListener('scroll', this.updateScrollButtonVisibility);
    setTimeout(() => {
      this.updateScrollButtonVisibility();
    }, 2500);
  },
  beforeDestroy () {
    const container = document.querySelector('.influence-container');
    container?.removeEventListener('scroll', this.updateScrollButtonVisibility);
  },
}
</script>

<style scoped>
.detail-influence-page-contaner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.influence-left-arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
}

.influence-right-arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-bottom: 5px;
}

.influence-right-scroll {
  cursor: pointer;
  width: 8px;
  margin-left: 5px;
}

.influence-left-scroll {
  cursor: pointer;
  width: 8px;
  margin-right: 5px;
}

.influence-container {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
}

.influence-container-item {
  flex-shrink: 0;
  width: 180px;
  height: 95%;
  margin-right: 20px;
  padding: 0px;
  box-sizing: border-box;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.5s ease;
  background-color: #fff;
  margin-top: 1%;
}

/* .influence-container-item::after {
  display: block;
  content: "";
  padding-top: calc(100% * (202 / 80));
} */

.influence-container-item:hover {
  transform: translateY(-5px);
}

.influence-container-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
}

.influence-container-item:hover::before {
  opacity: 1;
  border-radius: 8px;
}

.influence-container-item-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 10%;
  margin-top: 5%;
}

.influence-container-item-container-img {
  display: flex;
  flex-direction: row;
}

.influence-container-item-container-img img {
  width: 20px;
  height: auto;
  object-fit: contain;
}
.influence-container-item-title {
  margin-top: 0px;
  margin-left: 10px;
  color: #a8a8a8;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
  line-height: 1.1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.influence-container-item-detail {
  color: #2b2d31;
  font-size: 14px;
  font-family: Source Han Sans SC-Medium;
  line-height: 1.3;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.influence-container-item:hover {
  flex-shrink: 0;
  width: 180px;
  margin-right: 20px;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe);
  color: #fff;
}
.influence-container-item:hover * {
  color: #fff;
}

::-webkit-scrollbar {
  background-color: transparent;
}

/* 整个滚动条 */
::-webkit-scrollbar {
  width: 2px;
  border-radius: 3px;
  background-color: transparent;
  /* 隐藏水平滚动条 */
  height: 0;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #f1f1f1;
  border-radius: 6px;
}
</style>

<style scoped>
/* PC设备 */
@media only screen and (min-width: 1920px) {
  .detail-influence-page-contaner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .influence-left-arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .influence-right-arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    margin-bottom: 5px;
  }

  .influence-right-scroll {
    cursor: pointer;
    width: 8px;
    margin-left: 5px;
  }

  .influence-left-scroll {
    cursor: pointer;
    width: 8px;
    margin-right: 5px;
  }

  .influence-container {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .influence-container-item {
    flex-shrink: 0;
    width: 190px;
    height: 90%;
    margin-right: 20px;
    padding: 0px;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.5s ease;
    background-color: #fff;
    margin-top: 1%;
  }

  /* .influence-container-item::after {
  display: block;
  content: "";
  padding-top: calc(100% * (202 / 80));
} */

  .influence-container-item:hover {
    transform: translateY(-5px);
  }

  .influence-container-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 8px;
  }

  .influence-container-item:hover::before {
    opacity: 1;
    border-radius: 8px;
  }

  .influence-container-item-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: 10%;
    margin-top: 5%;
  }

  .influence-container-item-container-img {
    display: flex;
    flex-direction: row;
  }

  .influence-container-item-container-img img {
    width: 20px;
    height: auto;
    object-fit: contain;
  }
  .influence-container-item-title {
    margin-top: 0px;
    margin-left: 10px;
    color: #a8a8a8;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    line-height: 1.1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .influence-container-item-detail {
    color: #2b2d31;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.4;
  }

  .influence-container-item:hover {
    flex-shrink: 0;
    width: 190px;
    margin-right: 20px;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe);
    color: #fff;
  }
  .influence-container-item:hover * {
    color: #fff;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 3px;
    background-color: transparent;
    /* 隐藏水平滚动条 */
    height: 0;
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
}
</style>

<style scoped>
/* 移动设备 */
@media only screen and (max-width: 1024px) {
  .detail-influence-page-contaner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .influence-left-arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .influence-right-arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    margin-bottom: 5px;
  }

  .influence-right-scroll {
    cursor: pointer;
    width: 8px;
    margin-left: 5px;
  }

  .influence-left-scroll {
    cursor: pointer;
    width: 8px;
    margin-right: 5px;
  }

  .influence-container {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .influence-container-item {
    flex-shrink: 0;
    width: 170px;
    height: 95%;
    margin-right: 20px;
    padding: 0px;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.5s ease;
    background-color: #fff;
    margin-top: 1%;
  }

  /* .influence-container-item::after {
  display: block;
  content: "";
  padding-top: calc(100% * (202 / 80));
} */

  .influence-container-item:hover {
    transform: translateY(-5px);
  }

  .influence-container-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 8px;
  }

  .influence-container-item:hover::before {
    opacity: 1;
    border-radius: 8px;
  }

  .influence-container-item-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: 10%;
    margin-top: 8px;
  }

  .influence-container-item-container-img {
    display: flex;
    flex-direction: row;
  }

  .influence-container-item-container-img img {
    width: 20px;
    height: auto;
    object-fit: contain;
  }
  .influence-container-item-title {
    margin-top: 0px;
    margin-left: 10px;
    color: #a8a8a8;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    line-height: 1.1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .influence-container-item-detail {
    color: #2b2d31;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    line-height: 1.1;
    margin-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .influence-container-item:hover {
    flex-shrink: 0;
    width: 190px;
    margin-right: 25px;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe);
    color: #fff;
  }
  .influence-container-item:hover * {
    color: #fff;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 3px;
    background-color: transparent;
    /* 隐藏水平滚动条 */
    height: 0;
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
}
</style>

<style scoped>
/* 移动设备 */
@media only screen and (min-width: 1024px) and (max-width: 1360px) {
  .detail-influence-page-contaner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .influence-left-arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .influence-right-arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    margin-bottom: 5px;
  }

  .influence-right-scroll {
    cursor: pointer;
    width: 8px;
    margin-left: 5px;
  }

  .influence-left-scroll {
    cursor: pointer;
    width: 8px;
    margin-right: 5px;
  }

  .influence-container {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .influence-container-item {
    flex-shrink: 0;
    width: 170px;
    height: 80%;
    margin-right: 20px;
    padding: 0px;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.5s ease;
    background-color: #fff;
    margin-top: 1%;
  }

  /* .influence-container-item::after {
  display: block;
  content: "";
  padding-top: calc(100% * (202 / 80));
} */

  .influence-container-item:hover {
    transform: translateY(-5px);
  }

  .influence-container-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 8px;
  }

  .influence-container-item:hover::before {
    opacity: 1;
    border-radius: 8px;
  }

  .influence-container-item-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: 10%;
    margin-top: 8px;
  }

  .influence-container-item-container-img {
    display: flex;
    flex-direction: row;
  }

  .influence-container-item-container-img img {
    width: 20px;
    height: auto;
    object-fit: contain;
  }
  .influence-container-item-title {
    margin-top: 0px;
    margin-left: 10px;
    color: #a8a8a8;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    line-height: 1.1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .influence-container-item-detail {
    color: #2b2d31;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    line-height: 1.1;
    margin-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .influence-container-item:hover {
    flex-shrink: 0;
    width: 190px;
    margin-right: 25px;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe);
    color: #fff;
  }
  .influence-container-item:hover * {
    color: #fff;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 3px;
    background-color: transparent;
    /* 隐藏水平滚动条 */
    height: 0;
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
}
</style>


