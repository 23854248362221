<!-- Contact Seller弹窗 -->
<template>
  <div class="pop-wrapper"
       v-if="show">
    <div class="pop-mask"
         @click="close"></div>
    <div class="pop-dialog-bg">
      <div class="pop-dialog">
        <div class="pop-close">
          <span> </span>
          <img src="@/assets/login/close-no-cicle.png"
               @click="close"
               alt="" />
        </div>
        <span class="pop-title">{{$t('detail_pop_contact_seller')}}</span>

        <div class="contact-container">
          <div class="name-right-conntainer">
            <el-input v-model="inputContent"
                      :placeholder="$t('detail_pop_contact_seller_detail')"
                      type="textarea"
                      maxlength="1000"
                      resize="none"
                      :rows="isMobile ? 4 : 7"></el-input>
          </div>
        </div>

        <div class="makesure"
             @click="handleMakeSureClick">
          {{ $t('mine_submit') }}
        </div>

        <!-- <div class="bottom-tips">
          <span class="bottom-tips-1">{{ $t('detail_pop_tips1') }}</span>
          <span class="bottom-tips-2">{{ $t('detail_pop_tips2') }}</span>
          <span class="bottom-tips-1">{{ $t('detail_pop_tips3') }}</span>
          <span class="bottom-tips-2">{{ $t('detail_pop_tips4') }}</span>
          <span v-show="this.languageType == 'zh'"
                class="bottom-tips-1">{{ $t('detail_pop_tips5') }}</span>
        </div> -->

      </div>
    </div>
  </div>
</template>

<script>

import { userInfoApi } from '@/api/userApi.js'
// import { contactUsApi } from '@/api/common.js'
import { handleMessageSubmitApi } from '@/api/shop/shopApi.js'

export default {
  components: {

  },
  props: {
    detailData: {},
    // 店铺ID
    shopId: {
      type: [ String, Number],
      default: ''
    }
  },
  data () {
    return {
      inputContent: '',
      show: false,
      userInfo: {},
      languageType: "",
      isMobile: false,
    }
  },
  watch: {
    detailData (newVal) {
      if (newVal) {
        console.log("detailData.content:", this.detailData, this.detailData.projectName);
        if (this.languageType == 'zh') {
          this.inputContent = this.$t('detail_pop_contact_seller_tips1') + this.detailData.projectName + this.$t('detail_pop_contact_seller_tips2')
        } else {
          this.inputContent = this.$t('detail_pop_contact_seller_tips1') + this.detailData.projectNameEn + this.$t('detail_pop_contact_seller_tips2')
        }
      }
    }
  },

  created () {
    // 创建一个 MediaQueryList 对象，传入媒体查询条件
    var mediaQuery = window.matchMedia('(max-width: 1024px)');
    // 检查是否匹配指定的媒体查询条件
    if (mediaQuery.matches) {
      // 在此处执行适应小屏幕的代码
      console.log('屏幕宽度小于等于 1024px');
      this.isMobile = true;
    } else {
      // 在此处执行适应大屏幕的代码
      console.log('屏幕宽度大于 1024px');
      this.isMobile = false;
    }
    this.reloadLocationLanguageData()
    this.getUserInfoData()
  },

  methods: {
    reloadLocationLanguageData () {
      // 检查本地存储中的语言类型
      const language = localStorage.getItem('locationLanguageType')

      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
    },
    //获取用户信息
    getUserInfoData () {
      userInfoApi()
        .then(async (res) => {
          if (res.data.code === 200) {
            this.userInfo = res.data.data
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    open () {
      this.show = true
      document.body.style.overflow = 'hidden'
      this.name = this.customerName
    },
    close () {
      document.body.style.overflow = 'auto'
      this.$el.addEventListener(
        'animationend',
        () => {
          this.show = false
        },
        { once: true }
      )
      this.$el.classList.remove('fade-in')
      this.$el.classList.add('fade-out')
      this.handleRemove()
    },

    handleMakeSureClick () {
      this.submitData()
      // this.$emit('makeSureClick')
    },
    handleRemove () {
      // this.inputContent = ''
    },
    //提交数据
    submitData () {
      if (!this.inputContent) {
        this.$message.error(this.$t('tips_contact_message'));
        return false;
      }
      // const data = {
      //   companyName: this.userInfo.company,
      //   contact: '',
      //   email: this.userInfo.email,
      //   message: this.inputContent
      // }

      // contactUsApi(data)
      //   .then((res) => {
      //     if (res.data.code === 200) {
      //       this.$emit('makeSureClick')
      //     }
      //   })
      //   .catch((error) => {
      //   })
      const getData = {
        content: this.inputContent,
        shop_id: this.shopId
      }

      console.log('发送留言提交请求getData', getData)
      // 发送留言提交请求
      handleMessageSubmitApi(getData)
        .then((res) => {
          console.log(res)
          if (res.data.code === 200) {
            console.log("es.data.code === 200");
            this.$emit('makeSureClick')
          }
        })
        .catch((error) => {
          console.error(error)
          console.log("error.data.message", error.data.message);
          this.$message.error(error.data.message);
        })
    }
  }
}
</script>


<style scoped>
.pop-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  animation: fadeIn 0.5s ease-out;
}

.pop-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: -1;
}

.pop-dialog-bg {
  width: 822px;
  /* height: 444px; */
  height: 404px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
}

.pop-dialog {
  width: 820px;
  /* height: 442px; */
  height: 402px;
  border-radius: 29px;
  margin: 1px 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("~@/assets/bg/interlib-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.pop-close {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.pop-close img {
  width: 15px;
  height: auto;
  margin-right: 25px;
  cursor: pointer;
}

.pop-title {
  margin-top: 10px;
  color: #fff;
  font-size: 22px;
  font-family: Source Han Sans SC-Normal;
  width: 70%;
  text-align: center;
}

.contact-container {
  margin-top: 30px;
  margin-left: 60px;
  margin-right: 60px;
  display: flex;
  flex-direction: row;
}

.name-right-conntainer {
  width: 704px;
  height: 100%;
  margin: 0px 0px 0px 0px;
}

::v-deep .el-textarea__inner {
  background-color: transparent;
  border-radius: 20px;
  padding: 12px 15px;
  border: 1px solid #dcdfe6;
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Regular;
}

.makesure {
  margin-top: 40px;
  color: #fff;
  font-size: 16px;
  font-family: Source Han Sans SC-Medium;
  padding: 3px 30px;
  background-image: linear-gradient(
    to right,
    #ff036a,
    #d764e0,
    #6e2ff4,
    #1a56fe
  );
  border-radius: 18px;
  cursor: pointer;
}

.bottom-tips {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bottom-tips-1 {
  color: #a8a8a8;
  font-size: 15px;
  font-family: Source Han Sans SC-Regular;
  margin-right: 5px;
}

.bottom-tips-2 {
  color: #fff;
  font-size: 15px;
  font-family: Source Han Sans SC-Midium;
  margin-right: 5px;
}

.pop-wrapper.fade-out {
  animation: fadeOut 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>

<style scoped>
@media only screen and (max-width: 1024px) {
  .pop-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000000;
    animation: fadeIn 0.5s ease-out;
  }

  .pop-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: -1;
  }

  .pop-dialog-bg {
    width: 522px;
    /* height: 244px; */
    height: 214px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
    margin-bottom: 80px;
  }

  .pop-dialog {
    width: 520px;
    /* height: 242px; */
    height: 212px;
    border-radius: 20px;
    margin: 1px 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("~@/assets/bg/interlib-bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .pop-close {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
  }

  .pop-close img {
    width: 15px;
    height: auto;
    margin-right: 20px;
    cursor: pointer;
  }

  .pop-title {
    margin-top: -10px;
    color: #fff;
    font-size: 16px;
    font-family: Source Han Sans SC-Normal;
    width: 70%;
    text-align: center;
  }

  .contact-container {
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    flex-direction: row;
  }

  .name-right-conntainer {
    width: 434px;
    height: 100%;
    margin: 0px 0px 0px 0px;
  }

  ::v-deep .el-textarea__inner {
    background-color: transparent;
    border-radius: 15px;
    padding: 12px 15px;
    border: 0.5px solid #dcdfe6;
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
  }

  .makesure {
    margin-top: 15px;
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Medium;
    padding: 3px 20px;
    background-image: linear-gradient(
      to right,
      #ff036a,
      #d764e0,
      #6e2ff4,
      #1a56fe
    );
    border-radius: 15px;
    cursor: pointer;
  }

  .bottom-tips {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .bottom-tips-1 {
    color: #a8a8a8;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    margin-right: 5px;
  }

  .bottom-tips-2 {
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Midium;
    margin-right: 5px;
  }

  .pop-wrapper.fade-out {
    animation: fadeOut 0.3s ease-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
</style>